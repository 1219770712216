import React from 'react'

// Style //
import "./FormInput.css"

const FormInput = ({width, onchange, value, lable, placeholder, type, error, onKeyPress, max}) => {
  return (
    <div className='form_input_cont' style={{
      width: `${width}`
    }}  >
       {
        error? (<p className="red-txt">*{lable}</p>):(<p>{lable}</p>)
       }
       <input className={`input ${error? "red-border": ''}`} type={`${type}`} placeholder={`${placeholder}`}  value={value} onChange={(e)=>onchange(e.target.value)} onKeyPress={onKeyPress}  max={max?max:''}/>
    </div>
  )

}

export default FormInput