import axios from "axios";

// Get All Sites API //

export const getSiteProjectApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/get-site?page=${params?.page}${
      params?.limit ? `&limit=${params?.limit}` : ""
    }${params?.name ? `&name=${params.name}` : ""}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

// Get Sole-Traders By Sites API //
export const getSiteSoleTraderApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/get-site-sole-trader/${params.id}?page=${params?.page}${
      params?.limit ? `&limit=${params?.limit}` : ""
    }${params?.name ? `&name=${params.name}` : ""}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response, "response");
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};
export const getSiteTimesheetApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-site-timesheets?site_id=${params.id}`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};
export const getSiteTrainingApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-site-specific-training-matrix?site_id=${params.siteId}?&working_period_start=${params.formattedStartDate}&working_period_end=${params.formattedEndDate}`;
    console.log(url, "urlaaaaaa");

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response, "train");
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};
// Get SignIn By Sites API //
export const getSiteSignApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/sign_in_data?page=${params?.page}${
      params?.limit ? `&limit=${params?.limit}` : ""
    }${params?.name ? `&name=${params.name}` : ""}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

// get single site
export const getSingleSiteApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-single-site/${params}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.response?.data);
    }
  });
};

// Add Site/Project //
export const addSiteApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-site`,
        data: params,
      });

      resolve(response.data);
    } catch (error) {
      console.log("error::", error.response.data);
      reject(error.response.data);
    }
  });
};

// update Site/Project //
export const updateSiteApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/update-site/${params.id}`,
        data: params.data,
      });

      resolve(response.data);
    } catch (error) {
      console.log("error::", error.response.data);
      reject(error.response.data);
    }
  });
};

// Delete Sire/Projects //
export const deleteSiteApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/delete-site/${params}`;
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};
