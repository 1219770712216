import React from 'react'

// Router //
import { Routes, Route } from "react-router-dom"

// Pages //
import {SubConreactors, AddSubContractor, SubContractorProfile, EditTimesheet} from "../pages/index"
import AddEditSubcontractorTickets from '../pages/sub-contractor-profile/AddEditSubcontractorTickets'
import EditSubContractorEmail from '../pages/sub-contractors/EditSubContractorEmail'

const SubContractorRouts = () => {
    return (
        <Routes>
            <Route path="/" element={<SubConreactors />} />
            <Route path="add-subcontractor" element={<AddSubContractor />} />
            <Route path="sub-contractor-profile" element={<SubContractorProfile />} />
            <Route path="add-edit-contractor-tickets" element={<AddEditSubcontractorTickets />} />
            <Route path="edit-timeSheet/:id" element={<EditTimesheet />} />
            <Route path="edit-sub-contractor-email/:id" element={<EditSubContractorEmail />} />
        </Routes>
    )
}

export default SubContractorRouts