import React, { useEffect, useState } from 'react'
import { ButtonComp, PageHeading } from '../../common/components'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UsersTable from '../../common/components/sub-contractors-table/UsersTable';
import { receivedError, requestSent, responseRecived } from '../../redux/actions/utilsActions';
import { getUsersApi } from '../../api/users';

function Users() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userToken } = useSelector((state) => state.user)

  const [users, setUsers] = useState([])

  // UseEffect //
  useEffect(() => {
    getUsers()
  }, []);

  const getUsers = async () => {
    try {
      dispatch(requestSent());
      const data = await getUsersApi(userToken);
      setUsers(data.data);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  }

  return (
    <div id="sub_contractor">
      <PageHeading title={"USERS"} />
      <div className="filter_cont justify-content-between">
      <div></div>
        <ButtonComp
          onClick={() => navigate("/users/add-user")}
          title={"Add Users"}
          width={"25%"}
        />
      </div>
      <div className="right-sec_cont">
        <UsersTable data={users} />
      </div>
    </div>
  )
}

export default Users
