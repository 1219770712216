import React, {useState, useRef, useEffect} from "react";

// Style  //
import "./DropDown.css";
import Form from "react-bootstrap/Form";

const DropDown = ({ listItems, label, value, onSelect, width }) => {

  // Hooks //
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className="dropdown_cont"
      style={{
        width: width ? width : "",
      }}
    >
      <label>{label}</label>
      <div className={`btn_dropdown_wraper ${isOpen?'open':''}`} ref={dropdownRef}>
        <button className="dropdown_btn" onClick={()=>setIsOpen(!isOpen)}>{value?value:label} <i className="uil  fa-solid fa-chevron-down"></i></button>
        <div className="dropdown_options">
        {listItems.map((item, index) => (
          <p onClick={()=>{
            setIsOpen(false)
            onSelect(item)
          }} key={index}>{item}</p>
        ))}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
