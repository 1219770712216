import React, { useEffect, useState } from "react";
// Style //
import "./SubContractors.css";
import { images } from "../../../assets/index";

// Components //
import Table from "react-bootstrap/Table";
import { TableRowLoader } from "../../components/index";

// Hooks //
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSoleTraderDetailsApi } from "../../../api/soleTraderApi";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";

function SiteDetailsTable(siteTimesheetDetails) {
  // Hooks //
  const navigate = useNavigate();
  const { error, loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const [profileImagePath, setProfileImagePath] = useState("");
  const { userToken } = useSelector((state) => state.user);



  const getSoleTradersDetails = async () => {
    try {
      dispatch(requestSent());
      const data = await getSoleTraderDetailsApi(userToken);
      setProfileImagePath(data.profile_url);
      dispatch(responseRecived());
    } catch (error) {
      console.log("Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  useEffect(() => {
    getSoleTradersDetails();
  }, []);

  return (
    <>
      {loading ? (
        <TableRowLoader limit={8} />
      ) : (
        <Table className="common-table sub-contractor_table" bordered>
          <thead>
            <tr>
              <th>PAYMENT DATE</th>
              <th></th>
              <th>TIMESHEET PERIOD</th>
              <th></th>
              <th>NO. OF CONTRACTORS</th>
              <th></th>
              <th>SITE SPECIFIC TRAINING MATRIX</th>
            </tr>
          </thead>
          <tbody>
            {siteTimesheetDetails?.siteTimesheetDetails?.TimeSheets.map(
              (item, index) => {
             
                return (
                  <tr
                    key={index}
                    onClick={() =>
                      navigate(`/sites/site-traingMatrix?site_id=${item.site_id}`, {
                        state: {
                          siteInfo: {
                            working_period_start: item?.working_period_start,
                            working_period_end: item.working_period_end,
                          },
                        },
                      })
                    }
                  >
                    <td>
                      {item?.last_date_of_pay_roll
                        ? item?.last_date_of_pay_roll
                        : ""}
                    </td>
                    <td></td>
                    <td>
                      {item?.working_period_start
                        ? item?.working_period_start
                        : ""}
                      -
                      {item?.working_period_end ? item?.working_period_end : ""}
                    </td>
                    <td></td>
                    <td>
                      {item?.no_of_sole_traders ? item?.no_of_sole_traders : ""}
                    </td>
                    <td></td>
                    <td>
                      <button>View Details</button>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default SiteDetailsTable;
