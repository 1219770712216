import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import "rc-pagination/assets/index.css";
import Cookies from 'js-cookie';

import React from "react";

// component //
import { Sidebar, NavBar, OfflinePage } from "./common/components/index"
import { ToastContainer } from 'react-toastify'

// Router //
import Router from './routes/Router';

// Hooks //
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from 'react';

// API //
import { getSoleTradersApi } from "./api/soleTraderApi"
import { getContractorStatsApi } from "./api/overview"
import { getImageUrlApi } from "./api/commonApis"

// Actions //
import { numverOfSoleTraders, urlsAction } from "./redux/actions/utilsActions"
import { receiveExistUser } from "./redux/actions/authAction"
import { contractorStats } from "./redux/actions/contractorStatsAction"
import { NotFound } from './pages';

function App() {

  // Hooks //
  const { user, userToken } = useSelector((state) => state.user)
  const { error } = useSelector((state) => state.utils);
  const dispatch = useDispatch()
  const [online, setOnline] = useState(navigator.onLine);

  // API Calls //
  const getNoOfSoleTraders = async () => {
    try {
      const data = await getSoleTradersApi(userToken, {
        page: 1,
        limit: 10
      })
      const contractorStatsData = await getContractorStatsApi(userToken)
      dispatch(numverOfSoleTraders(data.data.count ? data.data.count : null))
      dispatch(contractorStats(contractorStatsData))
    } catch (error) {
      console.log("Log From App.js api Call", error);
    }
  }

  const getImageUrl = async () => {
    try {
      const url = await getImageUrlApi(userToken)
      dispatch(urlsAction(url))
    }
    catch (err) {
      console.log(err);
    }
  }


  //  ===============================  //
  const checkRememberMe = async () => {
    try {
      const rememberMe = await JSON.parse(localStorage.getItem("rememberMe"))
      if (rememberMe) {
        const userLocalData = await JSON.parse(localStorage.getItem("userData"))
        dispatch(receiveExistUser(userLocalData))
      } else {
        const userSessionData = JSON.parse(Cookies.get('userData'));
        dispatch(receiveExistUser(userSessionData))
      }
    } catch (error) {
      console.log("Error While Getting User From Sesson or Local Storage", error);
    }
  }

  useEffect(() => {
    if (userToken) {
      getNoOfSoleTraders()
      getImageUrl()
    }
  }, [userToken])

  useEffect(() => {
    if (error?.status === 404) {
      dispatch(receiveExistUser(null))
    }
  }, [error])

  useEffect(() => {
    checkRememberMe()
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Handlers //
  const handleOnline = () => {
    setOnline(true);
  };

  const handleOffline = () => {
    setOnline(false);
  };

  if (error?.status === 404) {
    return <NotFound />
  } else if (!online) {
    return (<><OfflinePage /></>)
  }

  return (
    <>
      <ToastContainer position='top-right' />
      <div id={`${user ? "app_cont" : ''}`}>
        {
          user ? (<div id='sidebar_cont'>
            <Sidebar />
          </div>) : ''
        }
        <div id='page_wraper'>
          {
            user ? (<NavBar />) : ''
          }
          <div className='page_cont'>
            <Router />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
