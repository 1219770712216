import "./ForgotPassword";
import React, { useState } from "react";

// Images //
import { images } from "../../../assets/index";

// Components //
import {
  FormInput,
  ButtonComp,
  Loader,
} from "../../../common/components/index";

import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// API's //
import { forgotPasswordLinkApi } from "../../../api/authApi";

const SendMailToForgotPass = () => {
  // Hooks //
  const { loading } = useSelector((state) => state.utils);
  const [error, setError] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow,setModalShow] = useState(false)

  // API Calls //
  const forgotPassLinkApiCall = async () => {
    try {
      dispatch(requestSent());
      setError("");
      // Login Api Call //
      const data = await forgotPasswordLinkApi({
        email: emailInput,
        type: "CONTRACTOR",
      });
      dispatch(responseRecived());
      setModalShow(true)
    } catch (error) {
      console.log("Login Page error::", error);
      // dispatch(receivedError(error));
      setError(error);
      setModalShow(false)
      dispatch(responseRecived());
    }
  };

  // Hendler //
  const forgotPassWordHendler = async () => {
    if (!emailInput) {
      alert("Please Fill all Field");
      return;
    }
    forgotPassLinkApiCall();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      forgotPassLinkApiCall();
    }
  };

  const modalHendler = () => {
    dispatch(receivedError(null));
    setModalShow(false)
  };

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="ml"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>Link Send</h2>
            <p>Please Check Your Email</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={modalHendler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CustomModal />
      <div className="login_wraper" id="forgot_pass">
        {loading ? <Loader /> : ""}
        <div className="login_form">
          <div className="header">
            <img src={images.subbyLogo} alt="" />
            <h2>Send Email</h2>
          </div>
          <div className="inp_fields_cont">
            <FormInput
              lable={"Email"}
              type={"Email"}
              placeholder={"Enter Email"}
              width={"100%"}
              onchange={(value) => setEmailInput(value)}
              value={emailInput}
              onKeyPress={handleKeyPress}
              error={error?.error_key === "password" ? true : false}
            />
            {error ? <p className="err_message">*{error.error_message}</p> : ""}
            <ButtonComp
              title={"Send"}
              width={"100%"}
              onClick={forgotPassWordHendler}
            />
            <div className="remembreme_forgote_pass  justify-content-center mt-5">
              <p onClick={() => navigate("/Login")}>Cancel</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMailToForgotPass;
