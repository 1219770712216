import { actionType } from "../actionTypes";

const initialState = {
  loading: false,
  error: null,
  soleTraders: null,
  urls: null
};

function utilsReducer(state = initialState, action) {
  switch (action.type) {

    // ================= Utils for loader and error ===============//

    case actionType.UTILS.REQUEST_SENT:
      return {
        ...state,
        loading: true,
      };

    case actionType.UTILS.RESPONSE_RECEVIED:
      return {
        ...state,
        loading: false,
      };

    case actionType.UTILS.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionType.UTILS.EMPTY_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case actionType.NO_OF_SOLE_TRADERS:
      return {
        ...state,
        soleTraders: action.payload
      };
    case actionType.URLS:
      return {
        ...state,
        urls: action.payload
      };
      
    default:
      return state;
  }
}

export default utilsReducer;