import React, { useState, useEffect } from "react";

// Style //
import "./Site.css";

// Images //
import { icons } from "../../assets/index";

// Table //
import Table from "react-bootstrap/Table";

// Components //
import {
  PageHeading,
  SearchBar,
  DropDown,
  ButtonComp,
  TableRowLoader,
} from "../../common/components/index";
import Pagination from "rc-pagination";

// Hooks //
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// API's //
import { getSiteProjectApi } from "../../api/siteProjectApi";

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
  numverOfSoleTraders,
} from "../../redux/actions/utilsActions";

const Site = () => {
  // Hooks //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user)
  const [sitesData, setSitesData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, show: 10 })
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState(8);
  const [limitPerPage, setlimitPerPage] = useState(10);

  const [siteName, setSiteName] = useState("");

  // API Calls //
  const getSiteProjectData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getSiteProjectApi(userToken, {
        page: value.page,
        limit: value.limit,
        name: value.name,
      });
      setSitesData(data.data.rows);
      setTotalCount(data?.data.count);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // Haandlers //
  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({ page: 1, show: value })
    getSiteProjectData({
      page: 1,
      limit: value,
      name: '',
    });
  };
  const handleSearch = (value) => {
    setSiteName(value);
    getSiteProjectData({
      page: 1,
      limit: 8,
      name: value,
    });
  };

  const updatePageHandler = (p) => {
    setSearchParams({ page: p, show: searchParams.get("show") })
    setCurrentPage(p);
    getSiteProjectData({
      page: p,
      limit: limitPerPage,
      name: '',
    });
  };

  // UseEffect //
  useEffect(() => {
    getSiteProjectData({
      page: searchParams.get("page"),
      limit: searchParams.get("show"),
      name: siteName,
    });
    setCurrentPage(Number(searchParams.get("page")))
    setlimitPerPage(Number(searchParams.get("show")))
  }, []);

  return (
    <div id="site_projects">
      <>
        <PageHeading title={"Sites / Projects"} />
        <div className="filter_cont justify-content-between">
          <div className="d-flex col-6">
            <DropDown
              label={"Show"}
              listItems={["2", "8", "10", "15", "20", "25"]}
              onSelect={handleShow}
              value={limitPerPage}
            />
            <SearchBar onChange={handleSearch} value={siteName} button={true} placeholder={"Search Sites"} />
          </div>

          <ButtonComp
            onClick={() => navigate("/sites/add-sites")}
            title={" Add Site/project"}
            width={"25%"}
          />
        </div>
        <div className="right-sec_cont">
          {loading ? (
            <TableRowLoader limit={limitPerPage} />
          ) : (
            <>
              <Table className="common-table " bordered>
                <thead>
                  <tr>
                    <th>SITE REF ID.</th>
                    <th>Client</th>
                    <th>Site / Project Name</th>
                    <th>Address</th>
                    <th>Post Code</th>
                    <th>Active Sub-Contractors</th>
                    <th>Site Specific Training Matrix</th>
                  </tr>
                </thead>
                <tbody>
                  {sitesData?.map((item, index) => (
                    <tr key={index} onClick={() => navigate(`/sites/site-details?site_id=${item.id}`, {
                      state: {
                        siteInfo: {
                          id: item.id,
                          siteName: item?.name,
                          reference: item.reference,
                          client_name: item.client_name
                        }
                      }
                    })}>
                      <td>{item.reference}</td>
                      <td>{item.client_name}</td>
                      <td className="text-uppercase">
                        {item?.name}
                      </td>
                      <td>{item?.address}</td>
                      <td>{item?.post_code}</td>
                      <td>
                        {item?.TimeSheets.length}
                      </td>
                      <td>
                        <button>
                          View Full Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="pagination_cont">
                <Pagination
                  pageSize={limitPerPage}
                  onChange={updatePageHandler}
                  current={currentPage}
                  total={totalCount}
                />
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default Site;
