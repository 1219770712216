import { actionType } from "../actionTypes";

const initialState = {
    user: null,
    userToken: null
}

function userReducer(state = initialState, action) {
    switch (action.type) {
        // ==================== Log In =================//

        case (actionType.LOGIN.SUCCESS):
            return {
                ...state,
                user: action.payload,
                userToken: action.payload?.contractorData.token
            }

        // ==================== Existing User =================//

        case (actionType.EXISTING_USER.SUCCESS):
            return {
                ...state,
                user: action.payload,
                userToken: action.payload?.contractorData.token
            }

        // ================= log Out ===================//

        case (actionType.LOG_OUT):
            return {
                ...state,
                user: null
            }

        default: return state
    };
}

export default userReducer;