import subbyLogo from "./images/subby-logo.png"
import uploadingCloud from "./images/uploading-cloud.svg"
import Loader from "./images/Eclipse-1s-200px.svg"
import allert from "./images/allert-Icon.svg"
import success from "./images/succes-icon.svg"
import logOutModal from "./images/logout-icon.svg"
import PersonPlaceHolder from "./images/person-placeholder.png"
import offline from "./images/offline.png"
import truck from "./images/truck.png"
import video from "./images/video.png"
import Csv from "./images/icons.png"
export default {
    subbyLogo,
    uploadingCloud,
    Loader,
    allert,
    logOutModal,
    success,
    PersonPlaceHolder,
    offline,
    truck,
    video,
    Csv
}