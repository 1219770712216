import React, { useState } from "react";

// Router //
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// Import Pages //
import {
  OverView,
  DownloadCSVFiles,
  ExpenseClaim,
  Settings,
  TimeSheetDetail,
  LogIn,
  ForgotPassword,
  NotFound,
  SendMailToForgotPass,
  SignUp,
} from "../pages/index";

// Routes //
import {
  TimeSheetRouts,
  SubContractorRouts,
  SitsAndProjectsRouts,
  AddDocumentsRouts,
  TrainingMatrixRoute,
  UsersRoute,
} from "./index";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logOutUser, loginUser } from "../redux/actions/authAction";
import Cookies from 'js-cookie';


const Router = () => {
  // Hooks //
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const [defaultPath, setDefaultPath] = useState('')

  useEffect(() => {
    if (location.pathname.startsWith('/admin')) {
      localStorage.clear()
      Cookies.remove('userData')
      dispatch(logOutUser())
      const rawData = location.search.slice(6);
      const nestedObject = reconstructObject(new URLSearchParams(rawData));
      setTimeout(() => {
        if(nestedObject.location && nestedObject.location !== 'undefined'){
          setDefaultPath(nestedObject.location)
          delete nestedObject.location;
        }
        dispatch(loginUser(nestedObject, false));
        Cookies.set("userData", JSON.stringify(nestedObject));
        navigate("/")
        return;
      }, 150)
    }
  }, [])

  useEffect(() => {
    if (defaultPath) {
      navigate(defaultPath)
    }
  }, [defaultPath])

  function reconstructObject(params) {
    const obj = {};
    for (const [key, value] of params) {
      const keys = key.split('.');
      let tempObj = obj;
      for (let i = 0; i < keys.length; i++) {
        const currentKey = keys[i];
        if (i === keys.length - 1) {
          tempObj[currentKey] = value;
        } else {
          tempObj[currentKey] = tempObj[currentKey] || {};
          tempObj = tempObj[currentKey];
        }
      }
    }
    return obj;
  }

  // Reconstruct the nested object

  return (
    <>
      {user ? (

        <Routes>
          <Route path="/" element={<OverView />} />
          <Route path="/timesheet-detail" element={<TimeSheetDetail />} />
          <Route path="/timesheets/*" element={<TimeSheetRouts />} />
          <Route path="/sub-contractors/*" element={<SubContractorRouts />} />
          <Route path="/sites/*" element={<SitsAndProjectsRouts />} />
          <Route path="/induction/*" element={<AddDocumentsRouts />} />
          <Route path="/training-matrix/*" element={<TrainingMatrixRoute />} />
          <Route path="/expense-claim" element={<ExpenseClaim />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/users/*" element={<UsersRoute />} />
          <Route path="/download-csv-files" element={<DownloadCSVFiles />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<LogIn />} />
          <Route path="/SignUp/:id" element={<SignUp />} />
          <Route path="/send-mail" element={<SendMailToForgotPass />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
        </Routes>
      )}
    </>
  );
};

export default Router;
