import Accordion from "react-bootstrap/Accordion";

// Components //
import { TimeSheetTable, TableRowLoader } from "../index";

// Hooks //
import { useDispatch, useSelector } from "react-redux";

const TimeSheetAccordianTable = ({ siteTimeSheetData , site_id}) => {
  // Hooks //
  const { error, loading } = useSelector((state) => state.utils);
  return (
    <Accordion defaultActiveKey={Number(site_id)}>
      {loading ? (
        <TableRowLoader />
      ) : (
        <>
          {siteTimeSheetData?.map((item, index) => (
            <Accordion.Item eventKey={item.id} key={index} className={item.id}>
              <Accordion.Header>{item.name}</Accordion.Header>
              <Accordion.Body>
                <TimeSheetTable timeSheetData={item?.TimeSheets} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </>
      )}
    </Accordion>
  );
};

export default TimeSheetAccordianTable;
