import React, { useState, useEffect } from "react";

// Style //
import "./Induction.css";

// Images //
import { icons, images } from "../../assets/index";

// Components //
import {
  PageHeading,
  ButtonComp,
  TableRowLoader,
  DropDown,
  SearchBar,
} from "../../common/components/index";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Pagination from "rc-pagination";

// API's //
import { deleteDocumentApi, deleteInductionVideoApi, getDocumentationApi } from "../../api/inductionAndDocumentation";

// Hooks //
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";
import { Button } from "react-bootstrap";

const Induction = () => {
  // Hooks //
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState("");
  const [limitPerPage, setlimitPerPage] = useState(5);
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [documentsData, setDocumentsData] = useState([]);
  const [inductionVideo, setInductionVideo] = useState('')
  const [downloadPath, setDownloadPath] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [sortObj, setSortObject] = useState({ sortBy: "", sortType: "ASC" });
  const dispatch = useDispatch();

  useEffect(() => {
    getDocumentsData({
      page: searchParams.get("page"),
      limit: limitPerPage,
    });
    setCurrentPage(Number(searchParams.get("page")));
  }, [currentPage, limitPerPage]);

  // API Calls //
  const getDocumentsData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getDocumentationApi(userToken, {
        page: value.page,
        limit: value.limit,
        name: value.name || "",
        sortBy: value.sortBy,
        sortType: value.sortType,
      });
      setInductionVideo(data.induction_video);
      setDocumentsData(data.data.rows);
      setDownloadPath(data.public_path);
      setTotalCount(data.data.count);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const deleteInductionVideo = async () => {
    try {
      dispatch(requestSent());
      await deleteInductionVideoApi(userToken);
      setInductionVideo('');
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };


  // Handlers //
  const updatePageHandler = (p) => {
    setSearchParams({ page: p });
    setCurrentPage(p);
  };

  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({ page: 1, show: value });
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    getDocumentsData({
      name: value,
      page: 1,
      limit: limitPerPage,
    });
  };

  const sortDocuments = async (value) => {
    const dataToGet = {
      sortBy: value,
      sortType: sortObj.sortType === "ASC" ? "DESC" : "ASC",
    };
    setSortObject(dataToGet);
    getDocumentsData({
      name: searchParams.get("name"),
      page: 1,
      limit: limitPerPage,
      ...dataToGet,
    });
  };

  // On Page Components //
  const CustomModal = () => {
    const deleteDocument = async () => {
      setModalShow(false)
      try {
        if(itemToDelete === 'video'){
          return deleteInductionVideo()
        }
        dispatch(requestSent());
        await deleteDocumentApi(userToken, itemToDelete);
        await getDocumentsData({
          page: searchParams.get("page"),
          limit: limitPerPage,
        })
        dispatch(responseRecived());
      } catch (error) {
        dispatch(receivedError(error));
        dispatch(responseRecived());
      }
    };

    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <h2>Are you sure you want to delete this document ?</h2>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn_style"
            variant="secondary"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="orange_btn btn_style"
            onClick={deleteDocument}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div id="induction">
      <CustomModal />
      <PageHeading title={"Induction & Documents"} />
      <div className="right-sec_cont">
        <div className="your_induction">
          <img src={icons.induction} alt="" />
          <div className="txt">
            <h3>Your Induction</h3>
            <p>
              Add required documents here for sub-contractors to sign, and also
              upload an induction video specific to your company that you want
              sub-contractors to watch before coming to site.
            </p>
          </div>
        </div>
        <div className="filter_cont justify-content-between align-items-center mt-3">
          <div className="d-flex justify-content-between">
            <DropDown
              label={"Show"}
              listItems={["2", "8", "10", "15", "20", "25"]}
              onSelect={handleShow}
              value={limitPerPage}
            />
            <SearchBar
              onChange={handleSearch}
              value={searchValue}
              type={"text"}
              width={"80%"}
              marginLeft={"25px"}
              placeholder={"Search Sub-Contractor"}
            />
          </div>

          <ButtonComp
            onClick={() => navigate("/induction/add-induction")}
            title={"Upload Induction Video"}
            width={"25%"}
            bgColor={"white"}
            style={{
              color: "black",
              marginLeft: 50,
              border: "1.5px solid black",
            }}
          />
          <ButtonComp
            onClick={() => navigate("/induction/add-documents")}
            title={"Add Documents"}
            width={"25%"}
          />
        </div>

        {loading ? (
          <TableRowLoader limit={8} />
        ) : (
          <>
            <Table className="common-table documents_table" bordered>
              <thead>
                <tr>
                  <th
                    role="button"
                    onClick={() => sortDocuments("site_ref_id")}
                  >
                    SITE REF NO.
                    {sortObj.sortBy === "site_ref_id" &&
                      (sortObj.sortType === "ASC" ? (
                        <i
                          class="fa-solid fa-arrow-up"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-arrow-down"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      ))}
                  </th>

                  <th role="button" onClick={() => sortDocuments("name")}>
                    Documents
                    {sortObj.sortBy === "name" &&
                      (sortObj.sortType === "ASC" ? (
                        <i
                          class="fa-solid fa-arrow-up"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-arrow-down"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      ))}
                  </th>

                  <th>View Document</th>
                  <th>EDIT DOCUMENT</th>
                  <th>DELETE DOCUMENT</th>
                </tr>
              </thead>
              <tbody>
                {inductionVideo && <tr>
                  <td>n/a</td>
                  <td>Induction Video</td>
                  <td
                    onClick={() => {
                      window.location.href = `${inductionVideo}`;
                    }}
                  >
                    <img
                      className="table_icon"
                      src={images.video}
                      alt=""
                      style={{ scale: "1.6", cursor: 'pointer' }}
                    />
                  </td>
                  <td
                    onClick={() =>
                      navigate("/induction/add-induction")
                    }
                  >
                    <i class="fa fa-pencil fa-2xl" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                  </td>
                  <td
                    onClick={() => {
                      setItemToDelete('video')
                      setModalShow(true)
                    }}
                  >
                    <i class="fa fa-trash fa-2xl" aria-hidden="true" style={{ cursor: 'pointer', color: 'var(--primary_color)' }}></i>
                  </td>
                </tr>}
                {documentsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.Site && item.Site.reference}</td>
                    <td>
                      {item?.name}
                    </td>

                    <td
                      onClick={() => {
                        if (item.image) {
                          // If item has an image, navigate to the specified download path
                          window.location.href = `${downloadPath}${item.image}`;
                        }
                        // Add additional conditions or actions if needed
                      }}
                    >
                      {item.image && (
                        <img
                          className="table_icon"
                          src={icons.Documents}
                          style={{ cursor: 'pointer' }}
                          alt=""
                        />
                      )}
                      {item.Site?.video_url && (
                        <img
                          className="table_icon"
                          src={images.video}
                          alt=""
                          style={{ scale: "1.6", cursor: 'pointer' }}
                        />
                      )}

                    </td>
                    <td
                      onClick={() =>
                        navigate(`/induction/edit-documents/${item.id}`)
                      }
                    >
                      <i class="fa fa-pencil fa-2xl" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                    </td>
                    <td
                      onClick={() => {
                        setItemToDelete(item.id)
                        setModalShow(true)
                      }}
                    >
                      <i class="fa fa-trash fa-2xl" aria-hidden="true" style={{ cursor: 'pointer', color: 'var(--primary_color)' }}></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="pagination_cont">
              <Pagination
                pageSize={limitPerPage}
                onChange={updatePageHandler}
                current={currentPage}
                total={totalCount}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Induction;
