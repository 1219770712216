// style //
import "./Sidebar.css";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

// Images //
import { images, icons } from "../../../assets/index";

// Hooks //
import { useDispatch, useSelector } from "react-redux";

// COmponents //
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Actions
import { logOutUser, loginUser } from "../../../redux/actions/authAction";
import {
  emptyErrors,
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";
import { getModuleApi } from "../../../api/sideBarApi";

const Sidebar = () => {
  // Hooks //
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const { userToken, user } = useSelector((state) => state.user);

  const [module, setModule] = useState([]);

  // On Page Components //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.logOutModal} alt="" />
            <h2>Do You Want To Log out?</h2>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn_style"
            variant="secondary"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </Button>
          <Button className="orange_btn btn_style" onClick={logoutHendler}>
            Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const logoutHendler = () => {
    dispatch(logOutUser());
    dispatch(loginUser(null, null));
    dispatch(emptyErrors());
    localStorage.clear("userData");
    sessionStorage.clear("userData");
    Cookies.remove("userData");
    localStorage.clear("userToken");
    setModalShow(false);
  };
  // Api For Modules

  const getModule = async () => {
    try {
      dispatch(requestSent());
      const data = await getModuleApi(userToken);
      setModule(data);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  useEffect(() => {
    getModule();

  }, []);

  return (
    <>
      <CustomModal />
      <nav>
        <div>
          <div className="logo_cont">
            <img src={images.subbyLogo} alt="" />
          </div>

          <div className="nav-link_cont">
            <div>
              <NavLink to={"/"}>
                <img src={icons.overView} alt="" />Overview
              </NavLink>
              {module?.contractor_modules?.map((data) => {
                switch (data?.module_type?.name) {
                  case "timesheet":
                    return (
                      <NavLink to={"/timesheets"}>
                        <img src={icons.timeSheet} alt="" />Timesheets
                      </NavLink>
                    );
                  case "sole-trader":
                    return (
                      <NavLink to={"/sub-contractors"}>
                        <img src={icons.subContractor} alt="" />Sub-Contractors
                      </NavLink>
                    );
                  case "site":
                    return (
                      <NavLink to={"/sites"}>
                        <img src={icons.site} alt="" />Sites/Projects
                      </NavLink>
                    );
                  case "documents":
                    return (
                      <NavLink to={"/induction"}>
                        <img src={icons.vector} alt="" />Inductions & Documents
                      </NavLink>
                    );
                  case "training-matrix":
                    return (
                      <NavLink to={`/training-matrix?pag=${1}`}>
                        <img src={icons.trainingMatrix} alt="" />Training Matrix
                      </NavLink>
                    );
                  case "expense-claims":
                    return (
                      <NavLink to={"/expense-claim"}>
                        <img src={icons.expenseClaim} alt="" />Expense Claims
                      </NavLink>
                    );
                  case "users":
                    if (user.contractorData.signatory_on_account) {
                      return (
                        <NavLink to={"/users"}>
                          <img src={icons.subContractor} alt="" />Users
                        </NavLink>
                      );
                    } else {
                      return;
                    }
                    {/* case "assets":
      return (
        <NavLink to={"/assets-allocation"}>
          <img src={icons.expenseClaim} alt="" /> Assets Allocation
        </NavLink>
      ); */}
                  default:
                    return null; // Or provide a default behavior
                }
              })}

              <NavLink to={"/download-csv-files"}>
                <img src={images.Csv} alt="" />
                Download CSV Files
              </NavLink>
            </div>
            <h3>Account</h3>
            <div>
              <NavLink to={"/settings"}>
                <img src={icons.setting} alt="" />Edit Your Profile
              </NavLink>
              <a className="logout_btn" onClick={() => setModalShow(true)}>
                <img src={icons.logOut} alt="" /> Log Out
              </a>
            </div>
          </div>
        </div>
        <div className="contact_us">
          <div className="icon_cont">
            <img src={icons.contactUs} alt="" />
          </div>
          <div className="txt_cont">
            <h3>Contact Us</h3>
            <p>help@subbygroup.co.uk</p>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Sidebar;
