// Style //
import "./Piechart.css";

import React, {useState} from "react";

// ApexCharts //
import ReactApexChart from "react-apexcharts";

const Piechart = ({series, chartLable, totalValue, toolTipLabels, indicatorColors}) => {

const options = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        enabledOnSeries: true,
        top: 1,
        left: 0,
        blur: 2,
        color: '#000',
        opacity: 0.1
    }
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        donut: {
          size: '80%',
          dataLabels: {
            enabled: false
          },
          legend: {
            fontSize: "1rem"
          },
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 28,
              formatter: () => chartLable
            },
        
            value: {
              fontSize: '1.5rem',
              fontWeight: 500,
              offsetY: -10
            },
            total: {
              show: true,
              showAlways: true,
              color: '#BCC1C8',
              fontSize: '.5rem',
              fontWeight: 600,
              formatter: (w) => {
                const total = w.globals.seriesTotals.reduce(
                  (a, b) => a + b,
                  0
                );
                return totalValue;
              }
            }
          }
        }
      },
    },
    dataLabels: {
      enabled: false
    },
    labels: toolTipLabels,
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 0,
      formatter: (value, opts) => {
        // return value + ' - ' + opts.w.globals.series[opts.seriesIndex];
        return value;
      },
      markers: {
        onClick: undefined,
        offsetX: -1,
        offsetY: 1
      }
    },
    fill: {
      type: 'solid',
      colors: indicatorColors, 
    },
    stroke: {
      width: 0
    },
    colors: indicatorColors, 

};

return (
    <div className="chart-wrap">
        <div className="donat_chart_cont">
            <ReactApexChart
                options={ options}
                series={series}
                type="donut"
                width={"100%"}
                height={"100%"}
            />
        </div>
    </div>
);
};

export default Piechart;
