import React, { useEffect, useState } from "react";

// Style //
import "./AddSites.css";
import { images } from "../../assets/index"

// Component //
import {
  FormInput,
  PageHeading,
  ButtonComp,
  Loader
} from "../../common/components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// API's //
import { addSiteApi, getSingleSiteApi, updateSiteApi } from "../../api/siteProjectApi"

// Actions //
import {
  responseRecived,
  requestSent,
} from "../../redux/actions/utilsActions";

const AddSites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user)
  const [searchParams] = useSearchParams();
  const siteId = searchParams.get("site_id");

  // Hooks //
  const [formData, setFormData] = useState({
    name: "",
    reference: "",
    client_name: "",
    post_code: "",
    address: "",
  });
  const [respMessage, setRespMessage] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (siteId) {
      getSingleSiteDoc()
    }
  }, [siteId])

  const getSingleSiteDoc = async () => {
    try {
      setError('')
      dispatch(requestSent());

      const data = await getSingleSiteApi(userToken, siteId);
      const siteData = data.data;
      setFormData({
        name: siteData.name,
        reference: siteData.reference,
        client_name: siteData.client_name,
        address: siteData.address,
        post_code: siteData.post_code,
      })
      dispatch(responseRecived())
    } catch (error) {
      console.log("Get Single Site/Prokect Page error::", error);
      setError(error)
      dispatch(responseRecived());
    }
  }

  // Handlers //
  const addSitesHendler = async () => {
    try {
      if (
        !formData.name ||
        !formData.reference ||
        !formData.client_name ||
        !formData.address ||
        !formData.post_code
      ) {
        alert("!!Please fill all the fields!!");
        return;
      }
      setError('')
      dispatch(requestSent());

      const data = await addSiteApi(userToken, formData);
      setRespMessage(data.message)
      dispatch(responseRecived())
      setModalShow(true)
    } catch (error) {
      console.log("Add Site/Prokect Page error::", error);
      setError(error)
      dispatch(responseRecived());
    }
  }

  const updateSitesHendler = async () => {
    try {
      if (
        !formData.name ||
        !formData.reference ||
        !formData.client_name ||
        !formData.address ||
        !formData.post_code ||
        !siteId
      ) {
        alert("!!Please fill all the fields!!");
        return;
      }
      setError('')
      dispatch(requestSent());

      const data = await updateSiteApi(userToken, { data: formData, id: siteId });
      setRespMessage(data.message)
      dispatch(responseRecived())
      setModalShow(true)
    } catch (error) {
      console.log("Add Site/Prokect Page error::", error);
      setError(error)
      dispatch(responseRecived());
    }
  }

  const modalHendler = () => {
    setModalShow(false)
    navigate("/sites")
  };

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>Site/Project  {siteId ? 'Updated' : 'Added'}</h2>
            <p>{respMessage}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={modalHendler}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <CustomModal />
      <PageHeading title={siteId ? "Edit Site/Project" : "Add A New Site/Project"} />
      <div id="add-subcontractor" className="right-sec_cont">
        <div>
          <h2>Site / Projects Details</h2>

          <FormInput
            width={"30vw"}
            lable="Site/Project Name"
            placeholder={"Site/Project Name"}
            onchange={(value) =>
              setFormData({
                ...formData,
                name: value,
              })
            }
            value={formData.name}
            type={"text"}
            error={error?.error_key === "name" ? true : false}
          />
          <FormInput
            width={"30vw"}
            lable="Internal Reference/Job Code"
            placeholder={"Enter Reference"}
            onchange={(value) =>
              setFormData({
                ...formData,
                reference: value,
              })
            }
            value={formData.reference}
            type={"text"}
            error={error?.error_key === "reference" ? true : false}
          />
          <FormInput
            width={"30vw"}
            lable="Client"
            placeholder={"Client"}
            onchange={(value) =>
              setFormData({
                ...formData,
                client_name: value,
              })
            }
            value={formData.client_name}
            type={"text"}
            error={error?.error_key === "client_name" ? true : false}
          />
          <FormInput
            width={"30vw"}
            lable="Address"
            placeholder={"Address"}
            onchange={(value) =>
              setFormData({
                ...formData,
                address: value,
              })
            }
            value={formData.address}
            type={"text"}
            error={error?.error_key === "address" ? true : false}
          />
          <FormInput
            width={"30vw"}
            lable="Postcode"
            placeholder={"Postcode"}
            onchange={(value) =>
              setFormData({
                ...formData,
                post_code: value,
              })
            }
            value={formData.post_code}
            type={"text"}
            error={error?.error_key === "post_code" ? true : false}
          />
          {/* <div className="select_inp form_input_cont">
            <p>Site Manager</p>
            <Form.Select
              className="input"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  site_manager: e.target.value,
                })
              }
              value={formData.site_manager}
            >
              <option>Site Manager A</option>
              <option>Site Manager B</option>
              <option>Site Manager C</option>
              <option>Site Manager D</option>
              <option>Site Manager E</option>
            </Form.Select>
          </div> */}
          {error ? <p className="err_message">*{error?.error_message}</p> : ''}
          <ButtonComp
            onClick={() => siteId ? updateSitesHendler() : addSitesHendler()}
            title={siteId ? "Edit Site/Project" : "Add Site/Project"}
            width={"30vw"}
          />
        </div>
      </div>
    </>
  );
};

export default AddSites;
