import axios from "axios";

export const downloadCsv = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-csv-files?type=${params}`
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
        },
        responseType: 'blob'
      })
      resolve(response.data)
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data)
    }
  })
}
