import axios from "axios";

// Get All Tickets API //
export const getTicketsApi = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-sole-trader-tickets`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                params: {
                    page: params.page,
                    limit: params.limit,
                    status: params.status
                }
            })
            resolve(response.data.data.rows)
        } catch (error) {
            console.log("api error::", error.response);
            reject(error.response)
        }
    })
}

// Get Images URL //
export const getImageUrlApi = async (userToken) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/common/get-image-urls`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}