import React, { useState, useEffect } from "react";

// Style //
import "./SubContractors.css";
import "rc-pagination/assets/index.css";

// Components //
import {
  PageHeading,
  SearchBar,
  DropDown,
  ButtonComp,
  SubContractorTable,
  TableRowLoader,
} from "../../common/components/index";
import Pagination from "rc-pagination";

// Hooks //
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// API's //
import { getSoleTradersApi } from "../../api/soleTraderApi";

// Actioms //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const SubContractors = () => {
  // Hooks //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {userToken} = useSelector((state)=> state.user)
  const [soleTraders, setSoleTraders] = useState([]);
  const[searchParams, setSearchParams] = useSearchParams({page:1, show: 10})

  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState(12);
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");

  
  // API Calls //
  const getSoleTraderData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getSoleTradersApi(userToken,{
        page: value.page,
        limit: value.limit,
        name: value.name,
      });
      setSoleTraders(data.data.rows);
      setTotalCount(data.data.count);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // Hendlers //
  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({page: 1, show: value})
    getSoleTraderData(
      {
        page: 1,
        limit: value,
        name: '',
      }
    );
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    getSoleTraderData({
      page: 1,
      limit: limitPerPage,
      name: value,
    });
  };

  const updatePageHandler = (p) => {
    setSearchParams({page: p, show: searchParams.get("show")})
    setCurrentPage(p);
    getSoleTraderData(
      {
        page: p,
        limit: limitPerPage,
        name: '',
      }
    );
  };

  // UseEffect //
  useEffect(() => {
    setCurrentPage(Number(searchParams.get("page")))
    getSoleTraderData(
      {
        page: searchParams.get("page"),
        limit: searchParams.get("show"),
        name: searchValue,
      }
    );
    setlimitPerPage(Number(searchParams.get("show")))
  }, []);

  return (
    <div id="sub_contractor">
      <PageHeading title={"Sub-Contractors"} />
      <div className="filter_cont justify-content-between">
        <div className="d-flex col-6">
          <DropDown
            label={"Show"}
            listItems={["2" ,"8", "10", "15", "20", "25"]}
            onSelect={handleShow}
            value={limitPerPage}
          />
          <SearchBar
            onChange={handleSearch}
            value={searchValue}
            type={"text"}
            placeholder={"Search Sub-Contractor"}
          />
        </div>

        <ButtonComp
          onClick={() => navigate("/sub-contractors/add-subcontractor")}
          title={"Add Sub-Contractor"}
          width={"25%"}
        />
      </div>
      <div className="right-sec_cont">
        <SubContractorTable data={soleTraders} />
        <div className="pagination_cont">
          <Pagination
            pageSize={limitPerPage}
            onChange={updatePageHandler}
            current={currentPage}
            total={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default SubContractors;