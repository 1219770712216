import React, { useState } from "react";

// Style //
import "./Settings.css";
import Cookies from 'js-cookie';
import { images } from "../../assets/index";

// Components //
import { FormInput, Loader } from "../../common/components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FileUploader } from "react-drag-drop-files";

// Hooks //
import { useSelector, useDispatch } from "react-redux";

// API's //
import { updateContractorApi } from "../../api/updateContractor";

// Actions //
import {
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

import { receiveExistUser } from "../../redux/actions/authAction";

const Settings = () => {
  // Hooks //
  const { userToken, user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.utils);
  const [error, setError] = useState('')
  const [updatedProfile, setUpdatedProfile] = useState({
    fullname: "",
    email: "",
    phone_number: "",
  });
  const [updatedImage, setUpdatedImage] = useState({
    fullname: "",
    logo: "",
  });
  const [updatedPassword, setUpdatedPassword] = useState({
    fullname: "",
    password: "",
  });

  const [newPass, setNewPass] = useState('')
  const fileTypes = ["JPEG", "PNG", "JPG"];

  const [updateModal, setUpdateModal] = useState(false);
  const [imageUploadModal, setImageUploadModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const dispatch = useDispatch();

  const updateContractorProfile = async (params) => {
    try {
      dispatch(requestSent());
      const updatedUser = await updateContractorApi(userToken, params)
      dispatch(receiveExistUser(updatedUser))
      localStorage.setItem("userData", JSON.stringify(updatedUser))
      Cookies.set("userData", JSON.stringify(updatedUser));
      setUpdatedImage({
        logo: '',
        fullname: ''
      })
      setUpdateModal(false);
      setImageUploadModal(false);
      setChangePasswordModal(false);
      setNewPass('')
      setError('')
      dispatch(responseRecived());
    } catch (error) {
      setError(error)
      dispatch(responseRecived());
    }
  };


  // Handlers //
  const closeModalHandler = () => {
    setUpdateModal(false);
    setImageUploadModal(false);
    setChangePasswordModal(false);
    setUpdatedProfile({
      fullname: "",
      email: "",
      phone_number: "",
    });
    setUpdatedImage({
      fullname: "",
      logo: "",
    });
    setUpdatedPassword({
      fullname: "",
      password: "",
    });
    setError('')
    setNewPass('')
  };

  const updatePasswordHandler = (params) => {
    if (!newPass) {
      alert("Please Fill All Fields")
      return
    }
    if (newPass === updatedPassword.password) {
      updateContractorProfile(params)
    } else {
      setError({
        error_key: "password",
        error_message: "Confirm password doesn't match New password"
      })
    }
  }
  const updateEmailMobileHandler = (params) => {
    if (!updatedProfile.email || !updatedProfile.fullname || !updatedProfile.phone_number) {
      alert("Please Fill All Fields")
      return
    }
    updateContractorProfile(params)
  }

  const updateModalHandler = () => {
    setUpdateModal(true);
  };

  const handleImageChange = (file) => {
    setUpdatedImage({
      ...updatedImage,
      logo: file,
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div id="ssettion_wraper">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={updateModal}
      >
        <>
          <Modal.Header className="d-flex justify-content-center">
            <h3>Update Contractor Profile</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="input_field_cont completed_by">
              <FormInput
                width={"100%"}
                lable="Email"
                placeholder={"Enter Your Email"}
                onchange={(value) =>
                  setUpdatedProfile({
                    ...updatedProfile,
                    email: value,
                  })
                }
                value={updatedProfile?.email}
                type={"text"}
                error={error?.error_key === "email" ? true : false}
              />
              <FormInput
                width={"100%"}
                lable="Mobile No."
                placeholder={"Enter Your Mobile No."}
                onchange={(value) =>
                  setUpdatedProfile({
                    ...updatedProfile,
                    phone_number: value,
                  })
                }
                value={updatedProfile?.phone_number}
                type={"number"}

                error={error?.error_key === "phone_number" ? true : false}
              />
              {error ? (
                <p className="err_message">*{error?.error_message}</p>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className=" btn_style"
              variant="secondary"
              onClick={closeModalHandler}
            >
              Close
            </Button>
            <Button
              onClick={() => updateEmailMobileHandler(updatedProfile)}
              className="btn_style orange_btn"
            >
              Update
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={imageUploadModal}
      >
        <>
          <Modal.Header className="d-flex justify-content-center">
            <h3>Update Contractor Profile</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="input_field_cont completed_by">
              <div className="drag_and_drop_cont form_input_cont">
                <p>Upload Image</p>
                <div className="drop-here">
                  <div className="txt_cont">
                    <img src={images.uploadingCloud} alt="" />
                    {updatedImage?.logo ? (
                      <p className="browse">{updatedImage?.logo.name}</p>
                    ) : (
                      <>
                        <p>Drag and drop here</p>
                        <p>or</p>
                        <p className="browse">browse</p>
                        <p className="file_type">
                          <b>File Type :</b>
                          {fileTypes.map((item, index) => (
                            <span key={index}>{item}</span>
                          ))}
                        </p>
                      </>
                    )}
                  </div>
                  <FileUploader
                    handleChange={handleImageChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
              </div>
              {error ? (
                <p className="err_message">*{error?.error_message}</p>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className=" btn_style"
              variant="secondary"
              onClick={closeModalHandler}
            >
              Close
            </Button>
            <Button
              onClick={() => updateContractorProfile(updatedImage)}
              className="btn_style orange_btn"
            >
              Update
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={changePasswordModal}
      >
        <>
          <Modal.Header className="d-flex justify-content-center">
            <h3>Update Contractor Profile</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="input_field_cont completed_by">
              <FormInput
                width={"100%"}
                lable="New Password"
                placeholder={"Enter Your New Password"}
                onchange={(value) =>
                  setNewPass(value)
                }
                value={newPass}
                type={"text"}
              />
              <FormInput
                width={"100%"}
                lable="Confirm Password"
                placeholder={"Enter Confirm Password"}
                onchange={(value) =>
                  setUpdatedPassword({
                    ...updatedPassword,
                    password: value,
                  })
                }
                value={updatedPassword?.password}
                type={"text"}
                error={error?.error_key === "password" ? true : false}
              />
              {error ? (
                <p className="err_message">*{error?.error_message}</p>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className=" btn_style"
              variant="secondary"
              onClick={closeModalHandler}
            >
              Close
            </Button>
            <Button
              onClick={() => updatePasswordHandler(updatedPassword)}
              className="btn_style orange_btn"
            >
              Update
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      <div className="profile_details_cont right-sec_cont">
        <div className="d-flex justify-content-end">
          <Button
            className="orange_btn"
            onClick={() => {
              updateModalHandler();
              setUpdatedProfile({
                fullname: user?.contractorData.Company.name,
                email: user?.contractorData.email,
                phone_number: user?.contractorData.phone_number,
              });
            }}
          >
            Update Profile
          </Button>
        </div>
        <div className="header">
          <div className="img_cont">
            <div className="profile_img_cont">
              <img src={user.contractorData?.Company.logo ? user.public_path + user.contractorData?.Company.logo : images.PersonPlaceHolder} alt="" />
            </div>
            <span
              className="edit-btn"
              onClick={() => {
                setUpdatedImage({
                  ...updatedImage,
                  fullname: user?.contractorData.fullname,
                  logo: ''
                });
                setImageUploadModal(true);
              }}
            >
              Edit logo <i className="fa-solid fa-pencil"></i>
            </span>
          </div>
          <div className="profile_txt">
            <h3>{user?.contractorData.Company.name}</h3>
            {/* <div className="edit_field">
              <h4>Thomas Walker</h4>
            </div> */}
          </div>
        </div>
        <div className="information_cont">
          <h3>Key Information</h3>
          <table className="information_cont_table">
            <tbody>
              <tr>
                <td>User Name</td>
                <td>{user?.contractorData.fullname.replace('__company', '')}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{user?.contractorData.email.replace('__company', '')}</td>
              </tr>
              <tr>
                <td>Password</td>
                <td>
                  *************
                  <span
                    className="edit-btn"
                    onClick={() => {
                      setUpdatedPassword({
                        ...updatedPassword,
                        fullname: user?.contractorData.fullname,
                        password: ''
                      });
                      setNewPass('')
                      setChangePasswordModal(true);
                    }}
                  >
                    Change Password<i className="fa-solid fa-pencil"></i>
                  </span>
                </td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>{user?.contractorData.phone_number}</td>
              </tr>
            </tbody>
          </table>
          <h3>Your Plan</h3>
          <table className="information_cont_table">
            <tbody>
              <tr>
                {/* <td>Subby Pro</td> */}
                <td>
                  £{user?.contractorData.Company.admin_charge} per subcontractor per week.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Settings;
