import overView from "./images/icons/overview-icon.svg"
import logOut from "./images/icons/logout.svg"
import timeSheet from "./images/icons/Timesheet-Icon.svg"
import subContractor from "./images/icons/sub-contractor-icon.svg"
import site from "./images/icons/site-icon.svg"
import vector from "./images/icons/Vector.svg"
import trainingMatrix from "./images/icons/training-matrix-icon.svg"
import expenseClaim from "./images/icons/expance-claim-icon.svg"
import setting from "./images/icons/settings.svg"
import mail from "./images/icons/mail.svg"
import Warning from "./images/icons/warning.svg"
import Documents from "./images/icons/documents-icon.svg"
import Check from "./images/icons/favicon.svg"
import ticket from "./images/icons/ticket-icon.svg"
import induction from "./images/icons/induction.svg"
import notificationBell from "./images/icons/Notification-Bell-icon.svg"
import contactUs from "./images/icons/contact-us-icon.svg"

export default {
    overView,
    logOut,
    timeSheet,
    subContractor,
    site,
    vector,
    trainingMatrix,
    expenseClaim,
    setting,
    mail,
    Warning,
    Documents,
    Check,
    ticket,
    induction,
    notificationBell,
    contactUs,
}