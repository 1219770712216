import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";

// Style //
import "./TrainingMatrix.css";

// Images //
import { icons } from "../../assets/index";

// Table //
import Table from "react-bootstrap/Table";

// Components //
import {
  ButtonComp,
  PageHeading,
  TableRowLoader,
} from "../../common/components/index";
import { checkTicketStatus, dateFormateFullMonth } from "../../constents";
import Pagination from "rc-pagination";

// Hooks //
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// API's //
import {
  getAllTicketsApi,
  getTrainingMatrixApi,
} from "../../api/trainingMatrixApis";

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const TrainingMatrix = () => {
  // Hooks //
  const { userToken } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const [ticketsData, setTicketsData] = useState([]);
  const [trainingMatrixData, setTrainingMatrixData] = useState([]);
  const navigate = useNavigate();
  const pdfContentRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState("10");
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const soleTraderId = searchParams.get("sole_trader_id");

  useEffect(() => {
    setCurrentPage(Number(searchParams.get("page")));
    getAllTicketsData(Number(searchParams.get("page")), soleTraderId);
  }, []);

  const getAllTicketsData = async (value, sole_trader_id) => {
    try {
      dispatch(requestSent());
      const tickets = await getAllTicketsApi(userToken, {
        page: value,
      });
      const data = await getTrainingMatrixApi(userToken, {
        page: value,
        sole_trader_id: sole_trader_id,
      });
      // setTicketsData(tickets.data.Tickets);
      setTicketsData(tickets.data.rows);
      setTrainingMatrixData(data.data.rows);
      setTotalCount(data.data.count);
      dispatch(responseRecived());
    } catch (err) {
      dispatch(receivedError(err));
      dispatch(responseRecived());
      console.log("Error from Training matrix ", err);
    }
  };

  // Handler //
  const updatePageHandler = (p) => {
    setSearchParams({ page: p });
    setCurrentPage(p);
    getAllTicketsData(p);
  };

  const handleExportPdfHandler = () => {
    const content = pdfContentRef.current;
    if (!content) {
      console.error("Content not found");
      return;
    }

    // myItemRef.current.style.display = 'block';
    content.childNodes[1].style.display = "none";

    const pdfOptions = {
      margin: 10,
      filename: "downloaded.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a1", orientation: "portrait" },
      exclude: [".btn_comp"],
    };

    html2pdf(content, pdfOptions);
    content.childNodes[1].style.display = "flex";
  };

  return (
    <div>
      <PageHeading
        title={"Training Matrix"}
        paragraph={"View your training matrix for your sub-contractors here."}
      />

      <div className="d-flex gx-5justify-content-between align-items-center my-3 w-50">
        <ButtonComp
          onClick={handleExportPdfHandler}
          title={"Export To PDF"}
          width={"49%"}
          bgColor={"white"}
          style={{
            color: "black",
            marginRight: "5px",
            border: "1px solid black",
            padding: "1rem",
          }}
        />
        <ButtonComp
          onClick={() => navigate("edit-training-matrix/")}
          title={"Add / Edit / Delete"}
          width={"49%"}
          style={{ padding: "1rem" }}
        />
      </div>
      <div ref={pdfContentRef}>
        {loading ? (
          <TableRowLoader limit={8} />
        ) : (
          <div className="training_matrix_table_cont">
            <div class="fixTableHead">
            <Table className="training_martix_table" bordered>
               <thead className="fixed-header">
                <tr>
                  <th className="bg-secondary">
                    <img
                      className="table_icon"
                      src={icons.subContractor}
                      alt=""
                    />
                  </th>
                  {ticketsData?.map((item, index) => (
                    <th key={index} className="bg-secondary text-light">{item?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {trainingMatrixData?.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {item?.SoleTraderProfile ? (
                        <b>{`${item?.SoleTraderProfile?.firstname} ${item?.SoleTraderProfile?.surname}`}</b>
                        ) : (
                          <b>{item?.email}</b>
                          )}
                    </td>

                    {ticketsData?.map((elem, index) => (
                      <td key={index}>
                        {item?.SoleTraderTickets?.filter(
                          (filteredticket) =>
                            filteredticket?.Ticket?.name === elem?.name
                          )?.map((ticket, index) => {
                            return (
                            <p
                              key={index}
                              className={`tickets-date ${ticket?.expiry_date && checkTicketStatus(
                                ticket?.expiry_date,
                                ticket.Ticket.createdAt
                              )}`}
                            >
                              {ticket.expiry_date && dateFormateFullMonth(ticket.expiry_date)}
                            </p>
                          );
                        })}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>
          </div>
        )}
        {soleTraderId ? (
          ""
        ) : (
          <div className="pagination_cont mt-3">
            <Pagination
              pageSize={limitPerPage}
              onChange={updatePageHandler}
              current={currentPage}
              total={totalCount}
            />
          </div>
        )}

        <div className="indicators_cont">
          <div className="indicators i-1">
            <span></span>
            <p>EXPIRED</p>
          </div>
          <div className="indicators i-2">
            <span></span>
            <p>EXPIRING SOON</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingMatrix;
