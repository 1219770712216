import React from 'react'

// Router //
import { Routes, Route } from "react-router-dom"

// Pages //
import {Induction, AddDocuments, AddInduction} from "../pages/index"
import EditDocuments from '../pages/add-documents/EditDocuments'

const AddDocumentsRouts = () => {
    return (
        <Routes>
            <Route path="/" element={<Induction/>} />
            <Route path="add-documents" element={<AddDocuments />} />
            <Route path="edit-documents/:id" element={<EditDocuments />} />
            <Route path="add-induction" element={<AddInduction />} />
        </Routes>
    )
}

export default AddDocumentsRouts