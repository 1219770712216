import React, { useEffect, useState } from 'react'
import './SubContractorProfile.css'
import { ButtonComp, PageHeading, SearchBar, TableRowLoader } from '../../common/components'
import { getAllTicketsApi, getUnuploadedAllTicketsApi } from '../../api/trainingMatrixApis'
import { useDispatch, useSelector } from 'react-redux'
import { receivedError, requestSent, responseRecived } from '../../redux/actions/utilsActions'
import { Form } from 'react-bootstrap'
import { images } from '../../assets'
import { FileUploader } from 'react-drag-drop-files'
import { addTicketsForSoleTrader } from '../../api/soleTraderApi'
import { useLocation, useNavigate } from 'react-router-dom'
import { convertDateFormat } from '../../utils/dateUtils'

const AddEditSubcontractorTickets = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();

    const navigate = useNavigate()

    const { userToken } = useSelector((state) => state.user);
    const { loading } = useSelector((state) => state.utils);
    const fileTypes = ['PNG', 'JPG'];
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(state.isEdit ? state.data.Ticket.name : '');
    
    const [formData, setFormData] = useState({
        ticket_id: state.isEdit ? state.data.ticket_id : null,
        sole_trader_ticket_id: state.isEdit ? state.data.id : null,
        expiry_date: state.isEdit ? convertDateFormat(state.data.expiry_date, 'DD/MM/YYYY', 'YYYY-MM-DD') : null,
        front_image: state.isEdit ? state.data.front_image : null,
        rear_image: state.isEdit ? state.data.rear_image : null,
        sole_trader_id: state.sole_trader_id,
        is_edit: state.isEdit
    });    

    const getTickets = async () => {
        try {
            dispatch(requestSent());
            const data = await getUnuploadedAllTicketsApi(userToken, state.sole_trader_id, formData.ticket_id);            
                       
            setTickets(data.data);
            dispatch(responseRecived())
        } catch (error) {
            console.log(error);
            dispatch(receivedError(error))
        }
    }

    const addTicketForSt = async () => {
        try {
            dispatch(requestSent())
            if (!formData.expiry_date || (!state.isEdit && !formData.front_image) || (!state.isEdit && !formData.rear_image) || !formData.ticket_id) {
                return alert('Please Fill All of the fields')
            };
            await addTicketsForSoleTrader(userToken, formData)

            dispatch(responseRecived())
            navigate(-1)
        } catch (error) {
            dispatch(receivedError(error))
            alert('Something went wrong')
            console.log(error);
        }
    }

    useEffect(() => {
        getTickets()
    }, [])

    if (loading) {
        return <TableRowLoader limit={8} />
    }

    return (
        <>
            <PageHeading title={"Add Tickets For Sub-Contractor"} />
            <div id="add-documents" className="right-sec_cont">
                <div>
                    <h2>Add Sole Trader Tickets</h2>
                    <div className="select_inp form_input_cont">
                        <p>Name of the ticket</p>
                        <Form.Select
                            className="input"
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setFormData(prev => ({
                                        ...prev,
                                        ticket_id: null,
                                    }));
                                    setSelectedTicket('')
                                } else {
                                    setFormData(prev => ({
                                        ...prev,
                                        ticket_id: tickets[e.target.selectedIndex - 1].id,
                                    }));
                                    setSelectedTicket(e.target.value)
                                }
                            }
                            }
                            value={selectedTicket}
                        >
                            <option></option>
                            {
                                tickets.map(sl => <option key={sl.id} id={sl.id}>{sl.name}</option>)
                            }
                        </Form.Select>
                    </div>

                    <div className="select_inp form_input_cont">
                        <p>Expiry Date</p>
                        <SearchBar
                            type={'date'}
                            onChange={(v) => setFormData(p => ({ ...p, expiry_date: v }))}
                            value={formData.expiry_date}
                        />
                    </div>

                    <div className="drag_and_drop_cont form_input_cont">
                        <p>Upload Your Ticket Front</p>
                        <div className="drop-here">
                            <div className="txt_cont">
                                <img src={images.uploadingCloud} alt="" />
                                {formData.front_image ? (
                                    <p className="browse">{formData?.front_image?.name || formData.front_image}</p>
                                ) : (
                                    <>
                                        <p>Drag and drop here</p>
                                        <p>or</p>
                                        <p className="browse">browse</p>
                                        <p className="file_type">
                                            <b>File Type :</b>
                                            {fileTypes.map((item) => (
                                                <span>{item}</span>
                                            ))}
                                        </p>
                                    </>
                                )}
                            </div>
                            <FileUploader
                                handleChange={(e) => { setFormData(prev => ({ ...prev, front_image: e })) }}
                                name="file"
                                types={fileTypes}
                            />
                        </div>
                    </div>

                    <div className="drag_and_drop_cont form_input_cont">
                        <p>Upload Your Ticket Back</p>
                        <div className="drop-here">
                            <div className="txt_cont">
                                <img src={images.uploadingCloud} alt="" />
                                {formData.rear_image ? (
                                    <p className="browse">{formData?.rear_image.name || formData?.rear_image }</p>
                                ) : (
                                    <>
                                        <p>Drag and drop here</p>
                                        <p>or</p>
                                        <p className="browse">browse</p>
                                        <p className="file_type">
                                            <b>File Type :</b>
                                            {fileTypes.map((item) => (
                                                <span>{item}</span>
                                            ))}
                                        </p>
                                    </>
                                )}
                            </div>
                            <FileUploader
                                handleChange={(e) => { setFormData(prev => ({ ...prev, rear_image: e })) }}
                                name="file"
                                types={fileTypes}
                            />
                        </div>
                    </div>

                    {/* {error ? <p className="err_message">*{error?.error_message}</p> : ''} */}
                    <ButtonComp
                        onClick={addTicketForSt}
                        title={"Save Ticket"}
                        width={"30vw"}
                    />
                </div>
            </div >
        </>
    )
}

export default AddEditSubcontractorTickets
