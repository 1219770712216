import React from "react";
import "./OfflinePage.css";
import { images } from "../../../assets/index";

const OfflinePage = () => {
  return (
    <div className="offline-container">
      <div className="text_cont">
        <p className="offline-text">Oops! Looks like you're <span> Offline</span>.</p>
        <i class="fa-regular fa-wifi-slash offline-icon"></i>
        <button
          className="offline-button orange_btn"
          onClick={() => window.location.reload()}
        >
          Retry
        </button>
      </div>
      <img className="offline-image" src={images.offline} alt="Offline" />
    </div>
  );
};

export default OfflinePage;
