import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import "./ErrorPage.css"

const NotFound = () => {
  return (
    <div className="not-found">
    <Container>
      <Row>
        <Col md={12} className="text-center">
          <h1 className="display-1">404</h1>
          <h2 className="display-4">Oops! Page Not Found</h2>
          <p className="lead">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
          <button
          className="offline-button orange_btn"
          onClick={() => window.location.reload()}
        >
          Retry
        </button>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default NotFound