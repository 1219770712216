import axios from "axios";

// Get all notifications //
export const getAllNotificationApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-all-notifications?page=${params?.page}&limit=${params?.limit}`
        try{
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            const notificationIds = response.data.data.rows.map((item)=>{
                return(
                    item.id
                )
            } )
            resolve({ ids:notificationIds,  data: response.data.data})
        }catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

// Get notifications Count //
export const getNotificationCountApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-notifications-count`
        try{
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            resolve(response.data)
        }catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

// Update notification status //
export const updateNotificationStatusApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/update-notifications-status`
        try{
            let response = await axios({
                method: "POST",
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data : params
            })
            resolve(response)
        }catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}