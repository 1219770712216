import axios from "axios";

// Get Side Module  //
export const getModuleApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/modules`;
    
    try {
     
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response?.data);
    } catch (error) {
      console.log("api error::", error?.response?.data);
      reject(error.response?.data);
    }
  });
};
