// style //
import "./NavBar.css";

import React, { useEffect, useState, useRef } from "react";

// Images //
import { icons, images } from "../../../assets/index";

// Components //
import Pagination from "rc-pagination";
import { TableRowLoader } from "../../components/index";

// Hooks //
import { useSelector } from "react-redux";
import { dateFormatFullMonth, dateFormateFullMonth } from "../../../constents";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Api's //
import { getAllNotificationApi, getNotificationCountApi, updateNotificationStatusApi } from "../../../api/notificationApis";

// Actions //
import {
  receivedError,
  responseRecived,
} from "../../../redux/actions/utilsActions";

const NavBar = () => {
  // Hooks //
  const { user, userToken } = useSelector((state) => state.user)
  const { data: contractorStats } = useSelector((state) => state.contractorStats);
  const [notifications, setNotifications] = useState(false);
  const [allNotificationData, setAllNotificationData] = useState([]);
  const [totalCount, setTotalCount] = useState(50);
  const [limitPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState(0)
  const navigate = useNavigate()


  const dropdownRef = useRef();

  // Handler //
  const notificationHandler = () => {
    if (!notifications) {
      getAllNotificationData(currentPage)
    }
    setNotifications(!notifications);
  };

  const updatePageHandler = (p) => {
    setCurrentPage(p);
    getAllNotificationData(p);
    // updateNotificationCount(p)
  };

  // Api Call //
  const getAllNotificationData = async (value) => {
    try {
      setLoading(true);
      const data = await getAllNotificationApi(userToken, {
        page: value,
        limit: limitPerPage,
      });

      await updateNotificationStatusApi(userToken, {
        notification_ids: data.ids,
      })
      setAllNotificationData(data.data.rows);
      setTotalCount(data.data.count);
      setLoading(false);
    } catch (error) {
      dispatch(receivedError(error));
      console.log("TimeSheet Api Error :::", error);
      dispatch(responseRecived());
      setLoading(false);
    }
  };

  const getAllNotificationCountData = async () => {
    try {
      const notifCount = await getNotificationCountApi(userToken)
      setCounts(notifCount.unreadNotificationCount);
    } catch (error) {
      dispatch(receivedError(JSON.stringify(error)));
      console.log("TimeSheet Api Error :::", error);
    }
  };


  // useEffect /
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNotifications(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (userToken) {
      getAllNotificationCountData(userToken);
    }
  }, [userToken]);

  return (
    <div className="nav-bar_cont">
      {/* {contractorStats?.duePayRun ? (
        <div className="text_cont">
          <img src={icons.Warning} alt="" />
          <p>
            Your Next Pay Run is Due to be completed by
            {` ${dateFormateFullMonth(contractorStats?.duePayRun?.last_date_of_pay_roll)}`}
          </p>
        </div>
      ) : (
        <div className="text_cont">
          <p>🙂</p>
          <p>There Are No Pay Runs  to be completed</p>
        </div>
      )} */}

      <div className="text_cont">
        <img src={icons.subContractor} alt="" />
        {contractorStats?.soleTraderMissingIf ? (
          <p>
            You have {contractorStats.soleTraderMissingIf} Sub-contractors who need to
            complete their setup
          </p>
        ) : (
          <p>
            You Do Not have Any Sub-contractors who need to complete their setup
          </p>
        )}
        {/* ============== Notification Container Start ================== */}
        <div className="notification_cont" ref={dropdownRef}>
          <button className="notification_btn" onClick={notificationHandler}>
            <img src={icons.notificationBell} />
            <span className="badge">
              {counts}
            </span>
          </button>
          <div className={`notification_wraper ${notifications ? "show" : ""}`}>
            <div className="header">
              <h2>Notifications</h2>
            </div>
            <div className="notification_body">
              {loading ? (
                <TableRowLoader limit={3} height={100} />
              ) : (
                <>
                  {allNotificationData?.map((item, index) => (
                    <div className="notify_card" key={index}>
                      <div className="top d-flex justify-content-between">
                        <h3>{item.type}</h3>
                        {item.ExpenseClaim ? (<p>{`${item.ExpenseClaim.amount_claimed_currency} ${item.ExpenseClaim.amount_claimed}`}</p>) : ''}
                      </div>
                      <div className="btm">
                        <div className="d-flex justify-content-between">
                          <p className="sole-trader">
                            {item?.SoleTrader?.SoleTraderProfile?.firstname
                              ? `${item?.SoleTrader?.SoleTraderProfile?.firstname} ${item?.SoleTrader?.SoleTraderProfile?.surname}`
                              : "Sole-Trader Name"}
                          </p>
                          <p>
                            <b>Site -</b> {item?.Site?.name}
                          </p>
                        </div>
                        {item.ExpenseClaim ? <p className="mt-1 clame_type">{item.ExpenseClaim.type}</p> : ''}
                        {item.TimeSheet ? (
                          <p className="msg mt-2">
                            This Sole Trader Has Submitted Their Timesheet
                          </p>
                        ) : ''}
                        {item.ExpenseClaim ? (
                          <p className="msg mt-2">
                            This Sole Trader Has Claimed The Expenses
                          </p>
                        ) : ''}
                        <div className="mt-2">
                          <p className="time">
                            <i className="fa-regular fa-calendar-days"></i>
                            {dateFormatFullMonth(item.createdAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="pagination_cont justify-content-center">
              <Pagination
                pageSize={limitPerPage}
                style={{maxWidth: '100%'}}
                onChange={updatePageHandler}
                current={currentPage}
                total={totalCount}
              />
            </div>
          </div>
        </div>
        {/* ============== Notification Container End ================== */}
      </div>
      <div className="profile_cont">
        <div className="profile-img">
          <img src={user.contractorData?.Company?.logo ? `${user.public_path}${user.contractorData.Company.logo}` : images.PersonPlaceHolder} alt="" />
        </div>
        <div className="profile-name_cont" onClick={() => navigate('/settings')}>
          <p>{user.contractorData?.Company?.name}</p>
          {/* <p>Digger Ticket</p> */}
        </div>
      </div>
    </div>
  );
};

export default NavBar;