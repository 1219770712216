import { combineReducers } from "redux";

import userReducer from "./reducers/authReducer";
import utilsReducer from "./reducers/utilsReducers";
import contractorStatsReducer from "./reducers/contractorStatsReduser";

const rootReducer = combineReducers({
    user: userReducer,
    utils: utilsReducer,
    contractorStats : contractorStatsReducer
});

export default rootReducer