import React from 'react'

// Router //
import { Routes, Route } from "react-router-dom"

// Pages //
import {TimeSheets, TimeSheetDetail, TimeSheetAccordian} from "../pages/index"

const TimeSheetRouts = () => {
    return (
        <Routes>
            <Route path="/" element={<TimeSheets />} />
            <Route path="timesheet-detail" element={<TimeSheetDetail />} />
            <Route path="by-site" element={<TimeSheetAccordian/>} />
        </Routes>
    )
}

export default TimeSheetRouts