import React, {useState} from 'react'

// Style //
import "./PageHeading.css"

const PageHeading = ({title, paragraph,companyName}) => {

  return (
    <div className='pageheader_cont'>
    <h3 className='page-heading'>{companyName}</h3>
    <h3 className='page-heading'>{title}</h3>
    {paragraph?(<p className='paragraph'>{paragraph}</p>):''}
    </div>
  )
}

export default PageHeading