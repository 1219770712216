import React from 'react'

// Router //
import { Routes, Route } from "react-router-dom"

// Pages //
import {Users, AddUser, EditUser} from "../pages/index"

const UsersRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<Users/>} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="edit-user" element={<EditUser />} />
        </Routes>
    )
}

export default UsersRoute