import React, { useState } from "react";
import { PageHeading } from "../../common/components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";
import {
  editSubcontractorEmail,
} from "../../api/soleTraderApi";
import { Button } from "react-bootstrap";

function EditSubContractorEmail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { userToken } = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState({
    email: searchParams.get("email"),
    sole_trader_id: id
  });

  const editEmail = async () => {
    try {
      dispatch(requestSent());
      await editSubcontractorEmail(userToken, formData);
      dispatch(responseRecived());
      navigate(-1)
      window.alert("Sole Trader Email successfully edited!");
    } catch (error) {
      console.log(error, "error");
      dispatch(receivedError(error));
      dispatch(responseRecived());
      window.alert(error?.error_message, "Error");
    }
  };

  return (
    <div id="timesheet">
      <PageHeading title={"Edit Sub-Contractor Email"} />
      <div className="form-control container">
        <div className="mb-3">
          <label for="exampleInputPassword1" className="form-label">
            Email
          </label>
          <input
            type="text"
            className="form-control"
            value={formData.email}
            onChange={(text) => {
              setFormData({
                ...formData,
                email: text.target.value,
              });
            }}
            id="exampleInputPassword1"
          />
        </div>

        <Button onClick={editEmail} className="btn btn-danger">
          Update
        </Button>
      </div>
    </div>
  );
}

export default EditSubContractorEmail;
