import axios from "axios";

// Get Sole-Trader Chart Data //
export const soleTraderChartDataApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-sole-trader-stats`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const data = response.data.data;
      const status = [];
      const count = [];
      data.forEach((element) => {
        status.push(element.status);
        count.push(element.count);
      });
      let total = 0;

      for (let i = 0; i < count.length; i++) {
        total += count[i];
      }
      resolve({ status: status, count: count, total: total });
    } catch (error) {
      console.log("api error::", error);
      reject(error);
    }
  });
};
export const soleTraderBarChartDataApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-sites-assigned-sole-traders`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response?.data?.data);
    } catch (error) {
      console.log("api error::", error?.response?.data);
      reject(error.response?.data);
    }
  });
};
export const downloadRecentTimesheetApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-recent-timesheet`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

// Get TimeSheet Chart Data //
export const getTimeSheetStatsApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-timesheet-stats`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const data = response.data.data;
      const status = [];
      const count = [];
      data.forEach((element) => {
        status.push(
          element.status === "PENDING"
            ? "To Be Completed"
            : element.status === "APPROVED"
            ? "Completed"
            : "Suggest To Edit"
        );
        count.push(element.count);
      });
      let total = 0;
      for (let i = 0; i < count.length; i++) {
        total += count[i];
      }
      resolve({ status: status, count: count, total: total });
    } catch (error) {
      console.log("api error::", error);
      reject(error.response);
    }
  });
};

// Get Pending Time Sheet //
export const getPendingTimeSheetApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-pending-timesheet`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("api error::", error);
      reject(error.response);
    }
  });
};

// get-contractor-stats //
export const getContractorStatsApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-contractor-stats`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("get-contractor-stats::", error);
      reject(error);
    }
  });
};
