import moment from "moment";

// Auth Token //
export const userToken =
  "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJmdWxsbmFtZSI6IllvZ2VzaCIsImVtYWlsIjoieW9nZXNodi5kZXZAZ21haWwuY29tIiwidHlwZSI6IkNPTlRSQUNUT1IiLCJpYXQiOjE2ODE3MzIyNzV9.B_bYvaHE_fBGLuJNCXB2sV_Ucx5uX8YGaeCk2j0I-XpHSoIJW5sLuBSMuh9Au5ccPUuS02fYtQMVx6E46nk0hg";

// Calculate Gross Amount //
export const grossAmount = (params) => {
  return (
    params.total_hour_recorded *
    (params.daily_pay_rate / params.dailyWorkingHour)
  );
};

// Calculate Amount Due //
export const amountDue = (params) => {
  return params.grossAmount + params.no_of_soleTraders * params.admin_charges;
};

//Date Formate "DD Month YYYY" //
export const dateFormatFullMonth = (date) =>
  moment(date).format("DD/MM/YYYY");

// // Date Formate "DD/MM/YYY" //
// export const dateFormate = (date) => moment(date).format('L');
export const dateFormateFullMonth = (date) =>
  moment(date, "DD/MM/YYYY").format("Do MMMM YYYY");

// Date Format "DD/MM/YYYY"
export const dateFormate = (date) =>
  moment(date, "DD/MM/YYYY").format("DD/MM/YYYY");

export const parseDate = (dateString) => {
  // Split the string into day, month, and year components
  var parts = dateString.split('/');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1; // Months are zero-based (0-11)
  var year = parseInt(parts[2], 10);

  // Create a Date object with the components
  var dateObject = new Date(year, month, day);

  return dateObject;
}

// Calculate Expiry Date of A Ticket //
export const calculateTicketExpiryDate = (
  createdAt,
  updatedAt,
  expiry_date
) => {
  const currentDate = new Date();
  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt);
  const expiryDate = new Date(expiry_date);

  // Calculate the difference in days between current date and expiry date
  const timeDifference = expiryDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  // Check if the ticket is expired
  if (currentDate > expiryDate) {
    return "EXPIRED";
  }

  // Check if the ticket is going to expire soon (within 90 days)
  if (daysDifference <= 90) {
    return "EXPIRE-SOON";
  }

  // Calculate the remaining days until expiry from today
  const remainingDays = Math.ceil(
    (expiryDate - currentDate) / (1000 * 60 * 60 * 24)
  );
  return `${remainingDays} day${remainingDays === 1 ? "" : "s"
    } remaining to expire from today`;
};

// Get 3 day after given date //

export const getThreeDaysAfterDate = (date) => {
  const inputDate = moment(date);

  const threeDaysAfterDate = inputDate.add(3, "days");

  return threeDaysAfterDate.toDate();
};

//   Check Ticket Expire status //
export const checkTicketStatus = (expiry_date) => {
  const splittedExpiryDate = expiry_date.split('/')
  const currentDate = new Date();
  const expiryDate = new Date(splittedExpiryDate[2], +splittedExpiryDate[1] - 1, splittedExpiryDate[0]);

  // Calculate the difference in days between current date and expiry date
  const timeDifference = expiryDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (currentDate.getTime() > expiryDate.getTime()) {
    return "expired";
  } else if (daysDifference <= 90) {
    return "expire_soon";
  } else {
    return "valid";
  }
};
