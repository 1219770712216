import React, { useEffect, useState } from "react";

// Style //
import "./ExpenseClaim.css";
import Form from "react-bootstrap/Form";

// Images //
import { icons } from "../../assets/index";

// Components //
import {
  PageHeading,
  DropDown,
  SearchBar,
  TableRowLoader,
} from "../../common/components/index";
import Table from "react-bootstrap/Table";
import { dateFormatFullMonth, dateFormateFullMonth, parseDate } from "../../constents";
import Pagination from "rc-pagination";

// API's //
import {
  changeExpenseStatusApi,
  downloadExpenseClaimApi,
  getExpenseClaimApi,
} from "../../api/expenseClaimApi";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const ExpenseClaim = () => {
  const TABS = {
    TAB_ONE: "Live Claims",
    TAB_TWO: "Settled Claims",
  };
  // Hooks //
  const { error, loading, urls } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user);
  const [tab, setTab] = useState(TABS.TAB_ONE);
  const [formData, setFormData] = useState({
    expense_claim_id: "",
    status: "",
  });
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    show: 10,
  });
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState(10);
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [dateValue, setDateValue] = useState("");
  const [status, setStatus] = useState("PENDING");
  const [statusData, setStatusData] = useState({});
  const [expenseClaimData, setExpenseClaimData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleStatusChange = async (itemId, status) => {
    try {
      setStatusData((prevStatusData) => ({
        ...prevStatusData,
        [itemId]: status,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // API Calls //
  const getExpenseClaimData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getExpenseClaimApi(userToken, {
        page: value.page,
        limit: value.limit,
        status: value.status,
        date: value.date,
      });
      setExpenseClaimData(data.data.rows);
      setTotalCount(data.data.count);

      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const ApproveRejectExpenseClaim = async (id, value) => {
    try {
      formData.expense_claim_id = id;
      formData.status = value;
      dispatch(requestSent());
      await changeExpenseStatusApi(userToken, formData);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // Handlers //
  const updatePageHandler = (p) => {
    setSearchParams({ page: p, show: searchParams.get("show") });
    setCurrentPage(p);
    getExpenseClaimData({
      page: p,
      limit: 8,
      status: status,
      date: dateValue,
    });
  };

  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({ page: 1, show: value });
    getExpenseClaimData({
      page: 1,
      limit: value,
      status: status,
      date: dateValue,
    });
  };

  const handleDateChange = (value) => {
    setDateValue(value);
    getExpenseClaimData({
      page: 1,
      limit: 8,
      status: "",
      date: value,
    });
  };

  const handleTabChange = () => {
    if (tab === TABS.TAB_ONE) {
      setTab(TABS.TAB_TWO)
      setStatus('APPROVED')
      getExpenseClaimData({
        page: searchParams.get("page"),
        limit: limitPerPage,
        status: "APPROVED",
        date: dateValue,
      });
    } else {
      setTab(TABS.TAB_ONE)
      setStatus('PENDING')
      getExpenseClaimData({
        page: searchParams.get("page"),
        limit: limitPerPage,
        status: "PENDING",
        date: dateValue,
      });
    }
  }

  const downloadExpenseClaimPdf = async (id) => {
    const data = await downloadExpenseClaimApi(userToken, id)
    const fileurl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = fileurl;
    link.setAttribute('download', `expense-claim.pdf`);
    document.body.appendChild(link);
    link.click();
  }

  // UseEffect //
  useEffect(() => {
    getExpenseClaimData({
      page: searchParams.get("page"),
      limit: limitPerPage,
      status: status,
      date: dateValue,
    });
    setCurrentPage(Number(searchParams.get("page")));
    setlimitPerPage(Number(searchParams.get("show")));
  }, []);

  return (
    <>
      <PageHeading
        title={"Expense Claim"}
        paragraph={
          "View your sub-contractors expense claim. These are to be paid direct to the sub-contractors by yourselves, and are separate to your payroll payments with subby"
        }
      />
      <div className="filter_cont justify-content-between">
        <div className="d-flex col-8 justify-content-between">
          <DropDown
            label={"Show"}
            listItems={["1", "2", "8", "10", "15", "20", "25"]}
            onSelect={handleShow}
            value={limitPerPage}
          />
          {/* <DropDown
            label={"Status"}
            listItems={["ALL", "APPROVED", "PENDING"]}
            onSelect={handleStatus}
            value={status}
            width={"11vw"}
          /> */}
          <SearchBar
            onChange={handleDateChange}
            value={dateValue}
            type={"date"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="tabs_cont" style={{ margin: "10px" }}>
        <span
          className={`tab-label ${tab === TABS.TAB_ONE ? "active" : ""}`}
          onClick={handleTabChange}
        >
          Live Claims
        </span>
        <span
          className={`tab-label  ${tab === TABS.TAB_TWO ? "active" : ""}`}
          onClick={handleTabChange}
          style={{ marginLeft: "10px" }}
        >
          Settled Claims
        </span>
      </div>
      <div className="tab_cont">
        {tab === TABS.TAB_ONE ? (
          <div className="right-sec_cont" id="expense_claim">
            {loading ? (
              <TableRowLoader limit={8} />
            ) : (
              <>
                <Table className="common-table" bordered>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Claimed By</th>
                      <th>Date Claimed</th>
                      <th>Site/Project</th>
                      <th>Description of Claim</th>
                      <th>Amount Claimed</th>
                      <th>Receipt / Invoice</th>
                      <th>Authorise / Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseClaimData?.map((item, index) =>

                      <tr
                        key={index}
                        style={{
                          display: statusData[item.id] ? "none" : "table-row",
                        }}
                      >
                        <td>
                          <img src={icons.expenseClaim} alt="" />
                        </td>
                        <td>
                          {`${item.SoleTrader?.SoleTraderProfile?.firstname}  
                        ${item?.SoleTrader?.SoleTraderProfile?.surname}`}
                        </td>
                        <td>
                          {dateFormatFullMonth(parseDate(item.amount_claimed_date))}
                        </td>

                        <td>{item.Site && item.Site.name}</td>
                        <td>{item.type}</td>

                        <td>
                          {item.amount_claimed
                            ? `${item.amount_claimed_currency} ${item.amount_claimed}`
                            : "Null"}
                        </td>
                        <td>
                          <button onClick={() => downloadExpenseClaimPdf(item.id)}>
                            Download
                          </button>
                        </td>
                        <td>
                          <div className="rounded-2 border">
                            <Form.Select
                              className="input"
                              onChange={(event) => {
                                const { value } = event.target;
                                setFormData({
                                  ...formData,
                                  status: value,
                                });
                                handleStatusChange(item.id, value);
                                // Call expenseClaimStatus with the selected value and item.id
                                if (
                                  value === "APPROVED" ||
                                  value === "REJECTED"
                                ) {
                                  ApproveRejectExpenseClaim(item.id, value);
                                }
                              }}
                              value={statusData[item.id]}
                            >
                              <option value=""></option>
                              <option value={"APPROVED"}>Authorised</option>
                              <option value={"REJECTED"}>Rejected</option>
                            </Form.Select>
                          </div>
                        </td>
                      </tr>

                    )}
                  </tbody>
                </Table>
                <div className="pagination_cont">
                  <Pagination
                    pageSize={limitPerPage}
                    onChange={updatePageHandler}
                    current={currentPage}
                    total={totalCount}
                  />
                </div>
              </>
            )}
          </div>
        ) : tab === TABS.TAB_TWO ? (
          <div className="right-sec_cont" id="expense_claim">
            {loading ? (
              <TableRowLoader limit={8} />
            ) : (
              <>
                <Table className="common-table" bordered>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Claimed By</th>
                      <th>Date Claimed</th>
                      <th>Site/Project</th>
                      <th>Description of Claim</th>
                      <th>Amount Claimed</th>
                      <th>Receipt / Invoice</th>
                      <th>Authorise / Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseClaimData?.map((item, index) =>
                      status === "APPROVED" && "REJECTED" ? (
                        <tr key={index}>
                          <td>
                            <img src={icons.expenseClaim} alt="" />
                          </td>
                          <td>
                            {`${item.SoleTrader?.SoleTraderProfile?.firstname}  
                  ${item?.SoleTrader?.SoleTraderProfile?.surname}`}
                          </td>
                          <td>
                            {dateFormatFullMonth(parseDate(item.amount_claimed_date))}
                          </td>

                          <td>{item.Site && item.Site.name}</td>
                          <td>{item.type}</td>

                          <td>
                            {item.amount_claimed
                              ? `${item.amount_claimed_currency} ${item.amount_claimed}`
                              : "Null"}
                          </td>
                          <td>
                            <button onClick={() => downloadExpenseClaimPdf(item.id)}>
                              Download
                            </button>
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                item?.status === "APPROVED"
                                  ? "#92D050"
                                  : item?.status === "REJECTED"
                                    ? "#FF0000"
                                    : "white",
                            }}
                          >
                            {item.status === "APPROVED"
                              ? "AUTHORISED"
                              : item.status === "REJECTED"
                                ? "REJECTED"
                                : ""}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )

                    )}
                  </tbody>
                </Table>
                <div className="pagination_cont">
                  <Pagination
                    pageSize={limitPerPage}
                    onChange={updatePageHandler}
                    current={currentPage}
                    total={totalCount}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ExpenseClaim;
