import axios from "axios";

// Get All Expense Claims //
export const getExpenseClaimApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-expense-claims?page=${params?.page}&limit=${params.limit}${params?.date ? (`&date=${params.date}`) : ''}${!params?.status || params?.status === "ALL" ? '': (`&status=${params.status}`)}${params.site_id ? (`&site_id=${params.site_id}`) : ''}`
        try{
            // const token = localStorage.getItem("userToken");
            // let userToken = userToken.replace(/['"]+/g, "")
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            resolve(response.data)
        }catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

// Download Expense Claim //
export const downloadExpenseClaimApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-expense-claim/${params}`
        try{
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                responseType: 'blob'
            })
            resolve(response.data)
        }catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

export const changeExpenseStatusApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject) => {
        try {
          let response = await axios({
            method: "POST",
            headers: {
              Authorization: userToken,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/change-expense-claim-status`,
            data: params,
          });
          resolve(response.data);
        } catch (error) {
          console.log("error::", error.response.data);
          reject(error.response.data);
        }
      });
}