import React from 'react'

// Router //
import { Routes, Route } from "react-router-dom"

// Pages //
import {Site, AddSites, SiteDetails} from "../pages/index"
import { SiteTraingMatrix } from '../common/components'

const SitsAndProjectsRouts = () => {
    return (
        <Routes>
            <Route path="/" element={<Site/>} />
            <Route path="add-sites" element={<AddSites />} />
            <Route path="site-details" element={<SiteDetails />} />
            <Route path="site-traingMatrix" element={<SiteTraingMatrix />} />
        </Routes>
    )
}

export default SitsAndProjectsRouts