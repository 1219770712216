import React, { useState, useEffect } from "react";

// Style //
import "./SubContractors.css";
import { images } from "../../../assets/index";

// Components //
import Table from "react-bootstrap/Table";
import { Loader, TableRowLoader } from "../../components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Hooks //
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSoleTraderApi,
  getSoleTraderDetailsApi,
  archieveSoleTraderApi,
} from "../../../api/soleTraderApi";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";

const SubContractorsTable = ({ data }) => {
  // Hooks //
  const navigate = useNavigate();
  const { error, loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const [respMessage, setRespMessage] = useState("");
  const [soleTraderToDelete, setSoleTraderToDelete] = useState(null)

  const getSoleTradersDetails = async () => {
    try {
      dispatch(requestSent());
      await getSoleTraderDetailsApi(userToken);
      dispatch(responseRecived());
    } catch (error) {
      console.log("Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const archieveSoleTrader = async (id, archieved) => {
    const formData = {
      archieve: archieved,
      sole_trader_id: id
    }

    try {
      dispatch(requestSent());
      const data = await archieveSoleTraderApi(userToken, formData);
      dispatch(responseRecived());
      if (data.archive) {
        toast.success("Sole trader archived successfully!", {
          position: "top-right",
          autoClose: 3000, // Auto close after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      navigate(0);
    } catch (error) {
      console.log("Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  useEffect(() => {
    getSoleTradersDetails();
  }, []);

  const deleteSoleTraderHandler = async () => {
    try {
      setDeleteModal(false);
      dispatch(requestSent());
      await deleteSoleTraderApi(userToken, soleTraderToDelete);
      dispatch(responseRecived());
      navigate(0);
    } catch (error) {
      console.log("Error to delete soleTrader:::", error);
      setDeleteModal(true);
      setRespMessage(error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const DeleteModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.allert} alt="" />
            {respMessage ? (
              <>
                <h2>You Cannot Delete This Soletrader</h2>
                <p>{respMessage?.error_message}</p>
              </>
            ) : (
              <>
                <h1>Are You Sure?</h1>
                <p>
                  Do you want to really delete this record?. This process cannot
                  be undone.
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={closeModalHandler}>
            Close
          </Button>
          {respMessage ? (
            ""
          ) : (
            <Button
              onClick={() => deleteSoleTraderHandler()}
              className="btn_style mr-4"
              variant="secondary"
            >
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };
  const closeModalHandler = () => {
    setDeleteModal(false);
    setRespMessage("");
  };

  return (
    <>
      <DeleteModal />
      {loading ? (
        <TableRowLoader limit={8} />
      ) : (
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table
            className="common-table sub-contractor_table"
            bordered
            style={{ width: "auto" }}
          >
            <thead style={{ width: "fit-content" }}>
              <tr>
                <th>Subby Refrence No.</th>
                <th></th>
                <th>Subcontractor Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Address</th>
                {/* <th></th> */}
                <th>Status</th>
                <th>Timesheet Completed By</th>
                <th>Day Rate Measure</th>
                <th>Rate of Pay</th>
                <th>View Details</th>
                <th>Edit Timesheet or Rate of Pay</th>
                <th>Change Email</th>
                <th>Archived Profile</th>
                <th>Reinstate</th>
                <th>Delete Profile</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ width: "fit-content" }}>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>
                    <div className="profile_cont">
                      <div className="profile-img">
                        <img
                          src={
                            item.SoleTraderProfile.selfie_image
                              ? `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/sole_trader/onboarding/` + item.SoleTraderProfile.selfie_image
                              : images.PersonPlaceHolder
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="profile-name_cont">
                      <p>
                        {item?.SoleTraderProfile?.firstname}{" "}
                        {item?.SoleTraderProfile?.surname}
                      </p>
                    </div>
                  </td>
                  <td>
                    {item?.email ? item?.email : ""}
                  </td>
                  <td>
                    {item?.SoleTraderProfile?.phone_number
                      ? item?.SoleTraderProfile?.phone_number
                      : ""}
                  </td>

                  <td>
                    {item?.address ? `${item?.address}, ${item?.SoleTraderProfile?.city}, ${item.SoleTraderProfile.postcode}` : (item?.SoleTraderProfile?.address ? `${item?.SoleTraderProfile?.address}, ${item?.SoleTraderProfile?.city}, ${item.SoleTraderProfile.postcode}` : '-')}
                  </td>

                  <td
                    style={{
                      backgroundColor:
                        item?.status === "ACTIVE"
                          ? "#92D050"
                          : item?.status === "INACTIVE"
                            ? "#FFC001"
                            : item?.is_archieved
                              ? "white"
                              : "",
                    }}
                  >
                    {item?.is_archieved ? "ARCHIVED" : item?.status || ""}
                  </td>
                  <td>
                    {item?.timesheet_completed_by
                      ? item?.timesheet_completed_by
                      : ""}
                  </td>
                  <td>
                    {item?.WorkUnit &&
                      (item.WorkUnit === "AREA"
                        ? "Measure"
                        : item.WorkUnit === "DAYS"
                          ? "Day Rate"
                          : item.WorkUnit === "HOURS"
                            ? "Hour Rate"
                            : "")}
                  </td>
                  <td>
                    {item?.Contractors[0]?.daily_pay_rate
                      ? `£${item?.Contractors[0]?.daily_pay_rate}`
                      : ""}
                  </td>
                  {item?.is_archieved === true ? (
                    <td></td>
                  ) : (
                    <td>
                      <button
                        onClick={() =>
                          navigate(
                            `/sub-contractors/sub-contractor-profile?id=${item.id}`
                          )
                        }
                      >
                        View Details
                      </button>
                    </td>
                  )}
                  <td>
                    <Link
                      className="link"
                      to={`/sub-contractors/edit-timeSheet/${item.id}`}
                    >
                      Edit
                    </Link>
                  </td>
                  <td>
                    <Link
                      className="link"
                      to={`/sub-contractors/edit-sub-contractor-email/${item.id}?email=${encodeURIComponent(item.email)}`}
                    >
                      Edit
                    </Link>
                  </td>
                  {item?.is_archieved === true ? (
                    <td></td>
                  ) : (
                    <td>
                      <button
                        style={{
                          borderColor: "orange",
                          borderWidth: "1",
                          fontWeight: "lighter",
                        }}
                        onClick={() => {
                          archieveSoleTrader(item.id, true);
                        }}
                      >
                        ARCHIVE
                      </button>
                    </td>
                  )}

                  {item?.is_archieved === false ? (
                    <td></td>
                  ) : (
                    <td>
                      <button
                        style={{
                          borderColor: "orange",
                          borderWidth: "1",
                          fontWeight: "lighter",
                        }}
                        onClick={() => {
                          archieveSoleTrader(item.id, false);
                        }}
                      >
                        REINSTATE
                      </button>
                    </td>
                  )}
                  <td>
                    <button
                      onClick={() => {
                        setSoleTraderToDelete(item.id);
                        setDeleteModal(true)
                      }}
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      DELETE
                    </button>
                  </td>
                  {/* <td>
                  <span>...</span>
                </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default SubContractorsTable;
