import React from 'react'

// Style //
import "./Loader.css"

// Image //
import {images} from "../../../assets/index"

const Loader = () => {
  return (
    <div className='Loader_cont' >
        <img src={images.Loader} alt="" />
    </div>
  )
}

export default Loader