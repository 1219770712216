import React, { useState, useEffect } from "react";

// Icon //
import { icons } from "../../assets/index";

// Style //
import "./SiteDetails.css";

import { images } from "../../assets/index";

// Components //
import {
  PageHeading,
  ButtonComp,
  DropDown,
  SearchBar,
  SiteDetailsTable,
} from "../../common/components/index";
import Pagination from "rc-pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Hooks //
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// API's //
import {
  getSiteSoleTraderApi,
  deleteSiteApi,
  getSiteSignApi,
  getSiteTimesheetApi,
} from "../../api/siteProjectApi";

// Actioms //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const SiteDetails = () => {
  // Hooks //
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const siteId = searchParams.get("site_id");
  const siteInfo = location.state && location.state.siteInfo;
  const { userToken } = useSelector((state) => state.user);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(12);
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const [respMessage, setRespMessage] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [siteTimesheetDetails, setSiteTimesheetDetails] = useState()
  // API Calls //
  const getSiteSoleTraderApiData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getSiteSoleTraderApi(userToken, {
        id: siteId,
        page: currentPage,
        limit: limitPerPage,
        name: value,
      });
      setTotalCount(data.data.count);
      dispatch(responseRecived());
    } catch (error) {
      console.log("Site Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getSiteTimesheetApiData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getSiteTimesheetApi(userToken, {
        id: siteId,

      });
      setSiteTimesheetDetails(data.data);

      dispatch(responseRecived());
    } catch (error) {
      console.log("Site Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getSiteSignInApiData = async (value) => {
    try {
      dispatch(requestSent());
      const signInData = await getSiteSignApi(userToken, {
        page: currentPage,
        limit: limitPerPage,
        name: value,
      });
      setTotalCount(signInData.data.count);
      dispatch(responseRecived());
    } catch (error) {
      console.log("Site Sole Trader Details Error::::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // Hendlers //

  const deleteSiteHandler = async () => {
    try {
      setDeleteModal(false);
      dispatch(requestSent());
      const resp = await deleteSiteApi(userToken, siteId);
      dispatch(responseRecived());
      navigate("/sites");
    } catch (error) {
      console.log("Error to delete Site:::", error);
      setDeleteModal(true);
      setRespMessage(error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };


  const handleShow = (value) => {
    setlimitPerPage(value);
    setCurrentPage(1);
  };
  const handleSearch = async (value) => {
    setDateValue(value);
    getSiteSoleTraderApiData(value);
    getSiteSignInApiData(value);
  };

  const updatePageHandler = (p) => {
    setCurrentPage(p);
  };

  const closeModalHandler = () => {
    setDeleteModal(false);
    setRespMessage("");
  };

  // Modal //
  const DeleteModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.allert} alt="" />
            {respMessage ? (
              <>
                <h2>You Cannot Delete This Site</h2>
                <p>{respMessage?.error_message}</p>
              </>
            ) : (
              <>
                <h1>Are You Sure?</h1>
                <p>
                  Do you want to delete this record?. This process cannot be
                  undone.
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={closeModalHandler}>
            Close
          </Button>
          {respMessage ? (
            ""
          ) : (
            <Button
              onClick={() => deleteSiteHandler()}
              className="btn_style mr-4"
              variant="secondary"
            >
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  // UseEffect //
  useEffect(() => {
    getSiteSoleTraderApiData();
    getSiteSignInApiData();
    getSiteTimesheetApiData()
  }, [limitPerPage, currentPage]);

  return (
    <div id="site-details">
      <DeleteModal />
      <PageHeading title={"Site/Project Details"} />
      <div className="right-sec_cont">
        <div className="profile_details_cont">
          <div className="header">
            <div className="site_icon">
              <img className="site_icon_img" src={icons.site} alt="" />
            </div>
            <div className="profile_txt">
              <h3>{siteInfo?.siteName}</h3>
              <div className="edit_field">
                <h4>{siteInfo?.reference}</h4>
              </div>
              <div className="tabs_cont">
                <span
                  className="active"
                >
                  Details
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="cont">
          <h3>Key Information</h3>
          <p>
            <b>Client</b> {siteInfo?.client_name}
          </p>
          <h3>Sub-Contractors Invited To Site/Project"</h3>
        </div>
        <div>
          <div className="filter_cont justify-content-between">
            <div className="d-flex col-7 justify-content-between">
              <DropDown
                label={"Show"}
                listItems={["5", "4", "8", "10", "15", "20", "25"]}
                onSelect={handleShow}
                value={limitPerPage}
              />
              <div className="date-group">
                <p>Payment Date Range</p>
                <SearchBar
                  type={"date"}
                  onChange={(e) => {
                    console.log(e);
                  }}
                />
                <SearchBar
                  type={"date"}
                  onChange={handleSearch}
                  value={dateValue}
                  marginLeft={'20px'}
                />
              </div>
            </div>
          </div>

          <SiteDetailsTable siteTimesheetDetails={siteTimesheetDetails} />
          <div className="pagination_cont">
            <Pagination
              pageSize={limitPerPage}
              onChange={updatePageHandler}
              current={currentPage}
              total={totalCount}
            />
          </div>
          <div style={{ display: 'flex', columnGap: '1rem' }}>
            <ButtonComp
              width={"30%"}
              bgColor={"black"}
              title={"Delete Site / Project"}
              onClick={() => setDeleteModal(true)}
            />
            <ButtonComp
              width={"30%"}
              bgColor={"#EB742E"}
              title={"Edit Site / Project"}
              onClick={() => navigate(`/sites/add-sites?site_id=${siteId}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;
