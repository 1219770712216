import { actionType } from "../actionTypes";

// ================ Login User =================//

  const receiveLogin = user => {
    return {
      type: actionType.LOGIN.SUCCESS,
      payload: user
    };
  };

  export const loginUser = (user, rememberMe) => async(dispatch) => {
    dispatch(receiveLogin(user));
    localStorage.setItem("rememberMe", JSON.stringify(rememberMe))
  };

  // ==================== User Exist =================== //


  export const receiveExistUser = user => {
    return {
      type: actionType.EXISTING_USER.SUCCESS,
      payload: user
    };
  };

  // ================== Logout =======================//
  export const logOutUser = ()=> {
    return {
      type: actionType.LOG_OUT,
    };
  };