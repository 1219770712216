import axios from "axios";

// Get All Users Data //
export const getUsersApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/get-users`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

export const getSingleUserApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/get-user/${params}`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

//  Delete user //
export const addUsersApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-user`,
        data: params
      })
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

//  edit user //
export const editUsersApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/edit-user`,
        data: params
      })
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

//  Delete user //
export const deleteUsersApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/delete-users/${params}`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

