import { actionType } from "../actionTypes";

const initialState = {
    data : null
}

const contractorStatsReducer = (state = initialState, action)=>{
   switch(action.type){
     case(actionType.CONTRACTOR_STATS):
      return{
        ...state,
        data: action.payload
      }
      default: return state
   }
}

export default contractorStatsReducer;