import React, {useState} from "react";

//  Style //
import "./SearchBar.css";

const SearchBar = ({onChange, value, type,width, placeholder,marginLeft}) => {

  const [focus, setFocus] = useState(false)

  return (
    <div className={`search_cont ${focus? 'focused': ''}`} style={{
      width: width?width:'', marginLeft:marginLeft?marginLeft:''
    }}>
      {
        type === "date"?'':(<i className="fa-solid fa-magnifying-glass"></i>)
      }
      <input type={type} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} placeholder={placeholder} value={value} onChange={(e)=>onChange(e.target.value)} style={{
        width: type === "date"?'93%':('87%')
      }}/>
       {
        type === "date"?(<i className="fa-regular fa-calendar-days"></i>):''
      }

    </div>
  );
};

export default SearchBar;
