export const actionType = {
   LOGIN: {
      REQUEST: "LOGIN_REQUEST",
      SUCCESS: "LOGIN_SUCCESS",
      FAILURE: "LOGIN_FAILURE",
   },

   SIGN_UP: {
      REQUEST: "SIGN_UP_REQUEST",
      SUCCESS: "SIGN_UP_SUCCESS",
      FAILURE: "SIGN_UP_FAILURE",
   },

   EXISTING_USER: {
      REQUEST: "LOGOUT_REQUEST",
      SUCCESS: "LOGOUT_SUCCESS",
      FAILURE: "LOGOUT_FAILURE",
   },

   UTILS: {
      REQUEST_SENT: 'REQUEST_SENT',
      RESPONSE_RECEVIED: 'RESPONSE_RECEVIED',
      REQUEST_ERROR: 'REQUEST_ERROR',
      EMPTY_ERRORS: " EMPTY_ERRORS"
   },

   USER_TOKEN: "USER_TOKEN",

   LOG_OUT: "LOG_OUT",

   NO_OF_SOLE_TRADERS: "NO_OF_SOLE_TRADERS",

   CONTRACTOR_STATS: "CONTRACTOR_STATS",

   URLS : "URLS"
}
