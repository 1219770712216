import React, { useState, useEffect } from "react";

// Style //
import "./TimeSheets.css";

// Components //
import {
  PageHeading,
  DropDown,
  SearchBar,
  TimeSheetTable,
} from "../../common/components/index";
import Pagination from "rc-pagination";

// API's //
import { getAllTimeSheet } from "../../api/timeSheetApi";

// Actions //
import {
  receivedError,
  responseRecived,
  requestSent,
} from "../../redux/actions/utilsActions";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const TimeSheets = () => {
  // Hooks //
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user)
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, show: 10 })
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState(10);
  const [timeSheetData, setTimeSheetData] = useState(null);
  const [calculationData, setCalculationData] = useState([]);
  const navigate = useNavigate();

  // API Calls //
  const getTimeSheetData = async (value) => {
    try {
      dispatch(requestSent());

      const data = await getAllTimeSheet(userToken, {
        page: value.page,
        limit: value.limit,
        startDate: value.startDate,
        endDate: value.endDate,
      });      
      setCalculationData(data.dataForCalculation[0])
      setTimeSheetData(data.data.rows);
      setTotalCount(data.data.count)
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      console.log("TimeSheet Api Error :::", error);
      dispatch(responseRecived());
    }
  };

  useEffect(() => {
    getTimeSheetData({
      page: searchParams.get("page"),
      limit: limitPerPage,
      startDate: startDateValue,
      endDate: endDateValue,
    });
    setCurrentPage(Number(searchParams.get("page")))
    setlimitPerPage(Number(searchParams.get("show")))
  }, [currentPage, limitPerPage, startDateValue, endDateValue]);

  // Handlers //
  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({ page: 1, show: value })
  };

  const updatePageHandler = (p) => {
    setSearchParams({ page: p, show: searchParams.get("show") })
    setCurrentPage(p);
  };

  const handleDateSearch = (value, type) => {
    if (type === 'startDate') {
      setStartDateValue(value);
    } else {
      setEndDateValue(value);
    }
  };

  return (
    <div id="timesheet">
      <PageHeading title={"Your Timesheets"} />
      <div className="filter_cont justify-content-between">
        <DropDown
          label={"Show"}
          listItems={["1", "2", "8", "10", "15", "20", "25"]}
          onSelect={handleShow}
          value={limitPerPage}
        />

        <div className="date-group">
          <p>Payment Date Range</p>
          <SearchBar
            type={'date'}
            onChange={(v) => handleDateSearch(v, 'startDate')}
            value={startDateValue}
          />
          <SearchBar
            type={'date'}
            onChange={(v) => handleDateSearch(v, 'endDate')}
            value={endDateValue}
          />
        </div>

        <div className="tab-btn_cont">
          <button className="tab-one active">Overview</button>
          <button
            className="tab-one "
            onClick={() => navigate("/timesheets/by-site")}
          >
            by Site / Project
          </button>
        </div>
      </div>
      <div>
        <TimeSheetTable timeSheetData={timeSheetData} calculationData={calculationData}/>
      </div>
      <div className="pagination_cont">
        <Pagination
          pageSize={limitPerPage}
          onChange={updatePageHandler}
          current={currentPage}
          total={totalCount}
        />
      </div>
    </div>
  );
};

export default TimeSheets;
