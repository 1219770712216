import React, { useState, useEffect } from "react";

// style //
import "./AddDocuments.css";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Image //
import { images } from "../../assets/index";

// Components  //
import {
  FormInput,
  ButtonComp,
  PageHeading,
  Loader
} from "../../common/components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useDropzone} from 'react-dropzone';

// API's //
import {addInductionApi} from "../../api/inductionAndDocumentation"

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const AddInduction = () => {

  // Hooks //
  const [formData, setFormData] = useState({
    // name: "",
    video: "",
  });
  const navigate = useNavigate();
  const [respMessage,setRespMessage] = useState('')
  const [modalShow,setModalShow] = useState(false)
  const {loading } = useSelector((state) => state.utils);
  const [error, setError] = useState('')
  const {userToken} = useSelector((state)=> state.user)

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'video/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFormData({
        ...formData,
        video: acceptedFiles[0]
      })
    }
  });

  const dispatch = useDispatch();

  const addDocumentHendler = async() => {
    try{
      if (
        !formData.video
      ) {
        alert("!! Please fill the field !!");
        return;
      }
      setError('')
      dispatch(requestSent());

      const data =  await addInductionApi(userToken, formData);
       setRespMessage(data.message)
       dispatch(responseRecived())
       setModalShow(true)
      }catch (error) {
        console.log("Add Induction Video Page error::", error);
        setError(error)
        dispatch(responseRecived());
      }
  };

  const modalHendler = () => {
    dispatch(receivedError(null));
    setModalShow(false)
    navigate("/induction")
  };

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
        <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>Induction Video Uploaded</h2>
             <p>{respMessage}</p>
        </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style"  onClick={modalHendler}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if(loading){
    return <Loader/>
  }

  return (
    <>
      <CustomModal/>
      <PageHeading title={"Induction & Documents : Add Documents"} />
      <div id="add-documents" className="right-sec_cont">
        <div>
          <h2>Induction Video</h2>
          <div className="drag_and_drop_cont form_input_cont form_input_video">
            <p>Upload Induction Video</p>
            <div className="drop-here" {...getRootProps()}>
              <div className="txt_cont">
                <img src={images.uploadingCloud} alt="" />
                {formData?.video ? (
                  <p className="browse">{formData.video.name}</p>
                ) : (
                  <>
                    <p>Drag and drop A Induction Video here </p>
                    <p className="browse">browse</p>
                  </>
                )}
              </div>
              <input accept="video/*" {...getInputProps()}/>
            </div>
          </div>
          {error? <p className="err_message">*{error?.error_message}</p>:''}
          <ButtonComp
            onClick={addDocumentHendler}
            title={"Add Induction Video"}
            width={"30vw"}
          />
        </div>
      </div>
    </>
  );
};

export default AddInduction;
