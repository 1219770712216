import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

// Style //
import "./SignUp.css";

// Images //
import { images } from "../../../assets/index";

// Components //
import {
  FormInput,
  ButtonComp,
  Loader,
} from "../../../common/components/index";
import { toast } from "react-toastify";

// Actions //
import { loginUser } from "../../../redux/actions/authAction";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// API's //
import { updateDetails } from "../../../api/authApi";

const SignUp = () => {
  // Hooks //
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  // Utils //
  const { error, loading } = useSelector((state) => state.utils);
  const { id } = params;

  const dispatch = useDispatch();

  // API Calls //
  const loginApiCall = async () => {
    try {
      const userDetails = {
        password: password,
        confirm_password: confirmPassword,
        id,
      };
      dispatch(requestSent());

      // Update Api Call //
      await updateDetails(userDetails);
      setPassword("");
      setConfirmPassword("");
      dispatch(responseRecived());

      navigate("/");
    } catch (error) {
      console.log("Update Page error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // Hendler //
  const loginHendler = async () => {
    if (!password || !confirmPassword) {
      alert("Please Fill all Field");
      return;
    }
    if(password !== confirmPassword){
      alert('Passwords do not match')
      return;
    }
    loginApiCall();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginHendler();
    }
  };

  return (
    <div className="login_wraper">
      {loading ? <Loader /> : ""}
      <div className="login_form">
        <div className="header">
          <img src={images.subbyLogo} alt="" />
          <h2>Update Your Details</h2>
        </div>
        <div className="inp_fields_cont">
          <FormInput
            lable={"Set New Password"}
            type={"password"}
            placeholder={"Enter Your New Password"}
            width={"100%"}
            onchange={(pass) => setPassword(pass)}
            value={password}
            error={error?.data}
            onKeyPress={handleKeyPress}
          />
          <FormInput
            lable={"Confirm New Password"}
            type={"password"}
            placeholder={"Enter Your Confirm Password"}
            width={"100%"}
            onchange={(pass) => setConfirmPassword(pass)}
            value={confirmPassword}
            error={error?.data}
            onKeyPress={handleKeyPress}
          />
          {error ? (
            <p className="err_message">*{error?.data?.error_message}</p>
          ) : (
            ""
          )}
          {/* <div className="remembreme_forgote_pass">
            <div className="check_box_cont">
              <input type="checkbox" id="remember-me" onChange={()=>setSememberMe(!rememberMe)}/>
              <label htmlFor="remember-me">Remember Me</label>
            </div>
            <p onClick={()=>navigate("/send-mail")}>Forgot password</p>
          </div> */}
          <ButtonComp
            title={"UPDATE DETAILS"}
            width={"100%"}
            onClick={loginHendler}
          />
          <p className="contact_us">
            Don't have an account? <span>Contact us</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
