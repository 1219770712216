import React, { useState } from "react";

// Style //
import "./SubContractors.css";
import { images } from "../../../assets/index";

// Components //
import Table from "react-bootstrap/Table";
import { TableRowLoader } from "../../components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
// Hooks //
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSoleTraderApi,
} from "../../../api/soleTraderApi";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";
import { deleteUsersApi } from "../../../api/users";

const UsersTable = ({ data }) => {
  // Hooks //
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null)

  const [respMessage, setRespMessage] = useState("");

  const deleteUsererHandler = async () => {
    try {
      setDeleteModal(false);
      dispatch(requestSent());
      await deleteUsersApi(userToken, deleteId);
      dispatch(responseRecived());
      navigate(0);
    } catch (error) {
      console.log("Error to delete soleTrader:::", error);
      setDeleteModal(true);
      setRespMessage(error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const closeModalHandler = () => {
    setDeleteModal(false);
    setRespMessage("");
    setDeleteId(null)
  };

  const DeleteModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.allert} alt="" />
            {respMessage ? (
              <>
                <h2>You Cannot Delete This User</h2>
                <p>{respMessage?.error_message}</p>
              </>
            ) : (
              <>
                <h1>Are You Sure?</h1>
                <p>
                  Do you want to really delete this record?. This process cannot
                  be undone.
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={closeModalHandler}>
            Close
          </Button>
          {respMessage ? (
            ""
          ) : (
            <Button
              onClick={deleteUsererHandler}
              className="btn_style mr-4"
              variant="secondary"
            >
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <DeleteModal />
      {loading ? (
        <TableRowLoader limit={8} />
      ) : (
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table
            className="common-table sub-contractor_table"
            bordered
            style={{ width: "100%" }}
          >
            <thead style={{ width: "fit-content" }}>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Address</th>
                <th>Signatory On Account</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ width: "fit-content" }}>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="profile-name_cont">
                      <p>
                        {item?.fullname}
                      </p>
                    </div>
                  </td>
                  <td>
                    {item?.email || ""}
                  </td>
                  <td>
                    {item?.phone_number || '-'}
                  </td>

                  <td>
                    {item?.address || '-'}
                  </td>

                  <td>
                    {item?.signatory_on_account ? 'Y' : 'N'}
                  </td>

                  <td>
                    <button
                      onClick={() => {
                        navigate(`/users/edit-user?user_id=${item.id}`)
                      }}
                      style={{ backgroundColor: "#EB742E", color: "white" }}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setDeleteId(item.id)
                        setDeleteModal(true)
                      }}
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      DELETE
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default UsersTable;
