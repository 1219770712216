import React, { useState, useEffect } from "react";

// Style //
import "./OverView.css";

// Components //
import { PieChart, PageHeading, Loader } from "../../common/components/index";
import {
  grossAmount,
  amountDue,
  dateFormateFullMonth,
  dateFormatFullMonth,
} from "../../constents";

// Images //
import { icons } from "../../assets/index";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
} from "recharts";
// Hooks //
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../redux/actions/utilsActions";

// API's //
import {
  soleTraderChartDataApi,
  getTimeSheetStatsApi,
  getPendingTimeSheetApi,
  soleTraderBarChartDataApi,
  downloadRecentTimesheetApi,
} from "../../api/overview";
import { getTicketsApi } from "../../api/commonApis";
import moment from "moment";

const OverView = () => {
  // Hooks Call //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.utils);
  const { user, userToken } = useSelector((state) => state.user);
  const [subContractorcount, setSubContractorCount] = useState([]);
  const [subContractorStatus, setSubContractorStatus] = useState([]);
  const [subContractorsTotal, setSubContractorsTotal] = useState(0);
  const [subContractorColorPalette, setSubContractorColorPalette] = useState([]);
  const [timeSheetStatsCount, setTimeSheetStatsCount] = useState([]);
  const [timeSheetStatsStatus, setTimeSheetStatsStatus] = useState([]);
  const [timeSheetStatsTotal, setTtimeSheetStatsTotal] = useState(0);
  const [expiringTickets, setExpiringTickets] = useState([]);
  const [pendingTimeSheet, setPendingTimeSheet] = useState(null);
  const [amountDueState, setAmountDue] = useState(0);
  const [showFillerText, setShowFillerText] = useState(false);
  const [chartData, setChartData] = useState([]);
  const lastDateOfPayRoll = pendingTimeSheet?.last_date_of_pay_roll;
  const [download, setDownload] = useState();
  const [downloadPath, setDownloadPath] = useState();

  if (moment(lastDateOfPayRoll, "DD/MM/YYYY", true).isValid()) {
    const threeDaysLater = moment(lastDateOfPayRoll, "DD/MM/YYYY").add(3, 'days');

    if (threeDaysLater.isValid()) {
      const dateFormate = (date) =>
        moment(date).format("Do MMMM YYYY");

      // Example usage with threeDaysLater:
      var formattedDate = dateFormate(threeDaysLater);
    } else {
      console.error('Invalid date after adding three days');
    }
  } else {
    // console.error('Invalid initial date');
  }

  // API Calls //
  const overViewPageApis = async () => {
    try {
      dispatch(requestSent());
      const [
        {
          count: subContractorCount,
          status: subContractorStatus,
          total: subContractorsTotal,
        },
        {
          count: timeSheetStatsCount,
          status: timeSheetStatsStatus,
          total: timeSheetStatsTotal,
        },
        pendingTimeSheetData,
        expiringTicketsData,
      ] = await Promise.all([
        soleTraderChartDataApi(userToken),
        getTimeSheetStatsApi(userToken),
        getPendingTimeSheetApi(userToken),
        getTicketsApi(userToken, { page: 1, limit: 5, status: "EXIPRE" }),
      ]);

      if (subContractorCount || timeSheetStatsCount) {
        setShowFillerText(true);
      }

      setSubContractorCount(subContractorCount);
      const statuses = subContractorStatus.map(e => {
        if (e === 'MISSINGDOCS') {
          return 'Pending Induction'
        } else {
          return e
        }
      })
      setSubContractorStatus(statuses);
      setSubContractorColorPalette(rearrangeColorPalette(statuses))
      setSubContractorsTotal(subContractorsTotal);

      setTimeSheetStatsCount(timeSheetStatsCount);
      setTimeSheetStatsStatus(timeSheetStatsStatus);
      setTtimeSheetStatsTotal(timeSheetStatsTotal);

      setPendingTimeSheet(pendingTimeSheetData);
      if (expiringTicketsData.length > 0) {
        setExpiringTickets(expiringTicketsData);
      } else {
        setExpiringTickets("");
      }
      const grossAmountState = grossAmount({
        total_hour_recorded: Number(pendingTimeSheetData?.total_hour_recorded),
        dailyWorkingHour: pendingTimeSheetData?.dailyWorkingHour,
        daily_pay_rate: pendingTimeSheetData?.SoleTrader.daily_pay_rate,
      });

      const amountDueState = amountDue({
        grossAmount: grossAmountState,
        no_of_soleTraders: pendingTimeSheetData?.no_of_sole_traders,
        admin_charges: pendingTimeSheetData?.SoleTrader.Contractors[0]
          ?.admin_charge
          ? pendingTimeSheetData?.SoleTrader.Contractors[0]?.admin_charge
          : 0,
      });

      setAmountDue(amountDueState);

      dispatch(responseRecived());
      // setTimeout(()=>{
      // }, 500)
    } catch (err) {
      console.log("OverView error::", err);
      dispatch(receivedError(err));
      dispatch(responseRecived());
    }
  };

  const getBarchartData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await soleTraderBarChartDataApi(userToken)
      setChartData(data);


      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const rearrangeColorPalette = (statuses) => {
    const colorMapping = {
      "ACTIVE": "#EB742E",
      "INACTIVE": "#47455F",
      "Pending Induction": "#E4E000"
    };

    // Create a new array to hold the rearranged colors
    let rearrangedColors = new Array(statuses.length);

    // Iterate through the statuses array and place the corresponding color in the correct position
    for (let i = 0; i < statuses.length; i++) {
      rearrangedColors[i] = colorMapping[statuses[i]];
    }

    return rearrangedColors;
  }

  const data = chartData.map((item) => ({
    name: item.name,
    value: item.SoleTraders.length,
  }));

  const clientData = chartData.map((item) => ({
    name: item.client_name,
    value: item.SoleTraders.length,
  }));

  const RoundedBar = (props) => {
    const { x, y, width, height } = props;
    return (
      <rect x={x} y={y} width={width} height={height} rx={7} ry={7} fill="#EB742E" />
    );
  };

  const downloadRecentTimesheet = async () => {

    try {
      dispatch(requestSent());
      const data = await downloadRecentTimesheetApi(userToken);
      setDownload(data?.document);
      setDownloadPath(data?.mediaUrl);

      dispatch(responseRecived());
      const documentUrl = `${downloadPath}${download}`;

      window.open(documentUrl);
    } catch (error) {
      dispatch(receivedError(error));
      console.log(error);
      dispatch(responseRecived());
    }


  };

  // useEffect //
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userToken) {
      overViewPageApis();
      getBarchartData()
    }
  }, [userToken]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <PageHeading
        title={`Welcome back ${user?.contractorData?.Company?.name.charAt(0).toUpperCase() +
          user?.contractorData.Company.name.slice(1)
          }`}
      />
      <div className="overview_cont">
        <div className="cont">
          <div className="chart_cont">
            <div className="chart">
              <h3>Sub-Contractors</h3>
              {subContractorcount.length > 0 ? (
                <PieChart
                  series={subContractorcount}
                  chartLable={"Sub-contractors"}
                  toolTipLabels={subContractorStatus}
                  totalValue={subContractorsTotal}
                  indicatorColors={subContractorColorPalette}
                />
              ) : (
                <>
                  {showFillerText ? (
                    <div className="pending_time_sheet_empty">
                      <div className="place_holder_img_cont">
                        <img src={icons.subContractor} alt="" />
                      </div>
                      <h2>There Are No Sub-Contractors</h2>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="chart">
              <h3>Active Sub-Contractors assigned to Contractors</h3>
              {timeSheetStatsCount.length > 0 ? (
                // <PieChart
                //   series={timeSheetStatsCount}
                //   chartLable={"To Be Completed"}
                //   toolTipLabels={timeSheetStatsStatus}
                //   totalValue={timeSheetStatsTotal}
                //   indicatorColors={["#EB742E", "#000000", "#C6FF53"]}
                // />

                <BarChart
                  width={150}
                  height={350}
                  data={data}
                  margin={{ top: 20, right: 30, left: 30, bottom: 90 }}
                  outerRadius={20}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis
                    dataKey="name"
                    axisLine={{ display: "none" }}
                    tick={{ angle: 90, dy: 45, interval: 0 }}
                  />
                  <YAxis axisLine={{ display: "none" }} />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar dataKey="value" shape={<RoundedBar />} />
                </BarChart>

              ) : (
                <>
                  {showFillerText ? (
                    <div className="pending_time_sheet_empty">
                      <div className="place_holder_img_cont">
                        <img src={icons?.subContractor} alt="" />
                      </div>
                      <h2>There Are No Active Sub-Contractors</h2>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          <div className="details_cont chart_cont">
            <div className="lft_sec chart">
              <div className="pending-timesheet_cont">
                <h3>Timesheet</h3>
                <div className="floting_elem">
                  <div className="circle c1"></div>
                  <div className="circle c2"></div>
                </div>
                <div className="header_cont">
                  <div className="img_cont">
                    <img src={icons.mail} alt="" />
                  </div>
                  {pendingTimeSheet ? (
                    <p>
                      Next Payment Date -{" "}
                      {formattedDate}
                    </p>
                  ) : (
                    <p>{dateFormatFullMonth()}</p>
                  )}
                </div>
                {pendingTimeSheet ? (
                  <div className="body">
                    {/* <div className="site_cont"> */}
                    <div className="amount-transfer_cont">

                      <div className="payroll_date"> <p className="payroll_date ">Status</p></div>
                      <div className="text_cont">
                        {/* <p className="payroll_date">Status</p> */}
                        <p className=" btnTextColor">
                          {pendingTimeSheet?.Site?.client_name}
                        </p>
                      </div>
                    </div>
                    <div className="amount-transfer_cont">
                      <div className="text_cont">
                        <p className="payroll_date">Invoice :</p>
                        {/* <p className="date">Download</p> */}
                      </div>
                      <div className="btn_cont" onClick={downloadRecentTimesheet}>
                        <button className="see-timesheet_btn ">Download</button>
                        {/* <p className="date">Subby Ltd.</p>
                        <p className="date">04-00-04</p>
                        <p className="date">32042942</p> */}
                      </div>
                    </div>
                    <div className="text_cont discription">
                      <p className="date">
                        {/* Payment must clear with us on 1st March 2023 to ensure
                        your sub-contractors are paid on time on the 2rd March
                        2023. */}
                        Payment Due Date -{" "}
                        {dateFormateFullMonth(pendingTimeSheet?.last_date_of_pay_roll

                        )}
                      </p>
                    </div>
                    {/* <div className="btn_cont">
                      <button
                        onClick={() =>
                          navigate(
                            `/timesheets/timesheet-detail?site_id=${pendingTimeSheet?.Site.id}`
                          )
                        }
                        className="see-timesheet_btn bg-primary_color"
                      >
                        See Timesheet
                      </button>
                    </div> */}
                    <div className="amount-transfer_cont">

                      <div className="payroll_date"> <p className="payroll_date">Status</p></div>
                      <div className="text_cont">
                        {/* <p className="payroll_date">Status</p> */}
                        <p className="statusTextColor">
                          {pendingTimeSheet?.Site?.client_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="pending_time_sheet_empty">
                      <div className="place_holder_img_cont">
                        <img src={icons.timeSheet} alt="" />
                      </div>
                      <h2>There Are No pending timesheets</h2>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="rgt_sec chart">
              <h3>Active Sub-Contractors assigned to Client</h3>
              {/* <h3>Expiring Tickets</h3>
              <ul className="expiring-tickets_list">
                {expiringTickets.length === 0 ? (
                  <>
                    <div className="pending_time_sheet_empty">
                      <div className="place_holder_img_cont">
                        <img src={icons.ticket} alt="" />
                      </div>
                      <h2>There Are No Expiring Tickets</h2>
                    </div>
                  </>
                ) : (
                  <>
                    {expiringTickets?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          navigate(
                            `/training-matrix?sole_trader_id=${item?.sole_trader_id}`
                          )
                        }
                      >
                        <div className="profile_cont">
                          <div className="profile-img">
                            <img src={item?.SoleTrader.logo} alt="" />
                          </div>
                          <div className="profile-name_cont">
                            <p>{item?.SoleTrader.email}</p>
                            <p>{item?.Ticket?.name}</p>
                          </div>
                        </div>
                        <div className="expiery_cont">
                          <p>
                            <b>Expiry:</b>
                          </p>
                          <p>{dateFormateFullMonth(item.expiry_date)}</p>
                        </div>
                      </li>
                    ))}
                  </>
                )}
              </ul>
              <button
                onClick={() => navigate("/training-matrix")}
                className="view_all_btn"
              >
                View All Tickets
              </button> */}
              <BarChart
                width={160}
                height={350}
                data={clientData}
                margin={{ top: 20, right: 30, left: 30, bottom: 90 }}
                outerRadius={20}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                  dataKey="name"
                  axisLine={{ display: "none" }}
                  tick={{ angle: 90, dy: 45, interval: 0 }}

                />
                <YAxis axisLine={{ display: "none" }} />
                {/* <Tooltip /> */}
                {/* <Legend /> */}
                <Bar dataKey="value" shape={<RoundedBar />} />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverView;
