import React, { useState, useEffect } from "react";

// Components //
import {
  PageHeading,
  TableRowLoader,
  DropDown,
  SearchBar,
} from "../../common/components/index";
import Table from "react-bootstrap/Table";

// Hooks //
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";

import { downloadCsv } from "../../api/downnloadCsv";

const DownloadCSVFiles = () => {
  // Hooks //
  const { error, loading } = useSelector((state) => state.utils);
  const { userToken, user } = useSelector((state) => state.user)
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 })

  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setlimitPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  const csvFolders = [
    {
      csvFileRef: '001',
      csvFileName: 'Sites \ Projects',
      downloadLink: 'sites'
    },
    {
      csvFileRef: '002',
      csvFileName: 'Sub-Contractors',
      downloadLink: 'sub-contractor'
    },
    {
      csvFileRef: '003',
      csvFileName: 'Induction Docs',
      downloadLink: 'induction-docs'
    },
    {
      csvFileRef: '004',
      csvFileName: 'training Matrix',
      downloadLink: 'training-matrix'
    },
    {
      csvFileRef: '005',
      csvFileName: 'Assets Allocation',
      downloadLink: 'assets-allocation'
    }
  ]

  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({ page: 1, show: value })
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
  };

  const downoadCsvs = async (type) => {
    if (type) {
      const data = await downloadCsv(userToken, type)
      const fileurl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileurl;
      link.setAttribute('download', `${type}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  }

  // UseEffect //
  useEffect(() => {
    setCurrentPage(Number(searchParams.get("page")))
  }, [currentPage, limitPerPage]);

  return (
    <div id="induction">
      <PageHeading title={"Download CSV Files"} />
      <div className="right-sec_cont">
        <div className="filter_cont justify-content-between align-items-center mt-3">
          <div className="d-flex justify-content-between">
            <DropDown
              label={"Show"}
              listItems={["2", "8", "10", "15", "20", "25"]}
              onSelect={handleShow}
              value={limitPerPage}
            />
            <SearchBar
              onChange={handleSearch}
              value={searchValue}
              type={"text"}
              width={'80%'}
              marginLeft={'25px'}
              placeholder={"Search"}
            />
          </div>
        </div>

        {loading ? (
          <TableRowLoader limit={8} />
        ) : (
          <>
            <Table className="common-table documents_table " bordered>
              <thead>
                <tr>
                  <th role="button">
                    CSV File Reference
                  </th>

                  <th>CSV File Name</th>

                  <th>Site Specific Training Matrix</th>
                </tr>
              </thead>
              <tbody>
                {csvFolders?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.csvFileRef}
                    </td>
                    <td>
                      {item.csvFileName}
                    </td>
                    <td>
                      {item.csvFileName === 'Assets Allocation' ? <p>Coming Soon</p> : <button onClick={() => downoadCsvs(item.downloadLink)}>Download</button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadCSVFiles;
