import React from 'react'

// Router //
import { Routes, Route } from "react-router-dom"

// Pages //
import {TrainingMatrix, EditAddTrainingMatrix, AddDocuments, AddInduction, EditDocuments} from "../pages/index"

const TrainingMatrixRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<TrainingMatrix/>} />
            <Route path="/edit-training-matrix" element={<EditAddTrainingMatrix/>} />
            <Route path="add-documents" element={<AddDocuments />} />
            <Route path="edit-documents/:id" element={<EditDocuments />} />
            <Route path="add-induction" element={<AddInduction />} />
        </Routes>
    )
}

export default TrainingMatrixRoute