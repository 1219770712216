import axios from "axios";

// Get All Sole-Traders Data //
export const getSoleTradersApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/get-sole-trader?page=${params?.page}${
      params?.limit ? `&limit=${params?.limit}` : ""
    }${params?.name ? `&name=${params.name}` : ""}`;
    console.log(url, "url");
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

// Get All Sole-Traders-timesheet Data //
export const getSoleTradersTimeasheetApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-sole-tader-timesheet/${params}}`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response);
      reject(error.response);
    }
  });
};

// Get Sole-Trader Details By Id //
export const getSoleTraderDetailsApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/get-sole-trader/${params ? params : ""}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log(error, "error");
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

// edit-timeSheet
export const editTimeSheet = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/edit-timesheet`;
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: url,
        data: params,
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

// edit-sole trader email
export const editSubcontractorEmail = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/edit-sole-trader-email`;
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: url,
        data: params,
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

export const archieveSoleTraderApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    console.log(params, "parmassss");
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/archieve-reinstate-sole-trader/?${params}`;
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: url,
        data: params,
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};
// Delete Sole-Trader //
export const deleteSoleTraderApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/delete-sole-trader/${params}`;
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

// Add Sole-Trader API //
export const addSoleTraderApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-sole-trader`,
        data: params,
      });
      resolve(response.data);
    } catch (error) {
      console.log("error::", error.response.data);
      reject(error.response.data);
    }
  });
};
// Add Sole-Trader API //
export const downloadMedicalSoleTraderApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-medical-questionnaire/${params}`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

export const downloadTicketAPi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/download-ticket/?sole_trader_ticket_id=${params.sole_trader_ticket_id}&contractor_id=${params.contractor_id}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        responseType: 'blob'
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};

export const addTicketsForSoleTrader = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/add-ticket-for-sole-trader`;
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          "Content-Type": "multipart/form-data",
        },
        url: url,
        data: params,
      });

      resolve(response.data);
    } catch (error) {
      reject(error.response?.data);
    }
  });
};

export const downloadAllTicketAPi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${
      process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT
    }/contractor/download-all-tickets/?sole_trader_id=${params.sole_trader_id}&contractor_id=${params.contractor_id}`;
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        responseType: 'blob'
      });
      resolve(response.data);
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data);
    }
  });
};
