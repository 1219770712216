import React from 'react'

// Style //
import "./ButtonComp.css"

const ButtonComp = ({title, onClick, width, bgColor, style}) => {
  return (
    <button onClick={onClick} className="btn_comp" style={{
      width: `${width}`,
      backgroundColor: `${bgColor}`,
      ...style,
    }}>
            {title}
    </button>
  )
}

export default ButtonComp;