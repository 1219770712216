import {configureStore, applyMiddleware} from '@reduxjs/toolkit';
import thunkMiddleware from "redux-thunk";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import rootReducer from './rootReducer';



const persistConfig = {
  key: 'root',
  storage,  
  whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = configureStore(
  {
    reducer: persistedReducer,
  },
  applyMiddleware(thunkMiddleware)
);

// export const store = createStore(persistedReducer, persistedInitialState);

export const persistor = persistStore(createStore);