import axios from "axios";

// Get Training Matrix //
export const getTrainingMatrixApi = async (userToken, params) => {
    console.log(params);
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-training-matrix${params.page?`?page=${params.page}`:''}${params.sole_trader_id?`&sole_trader_id=${params.sole_trader_id}`:''}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
          resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}
// Get All Tickets //
export const getAllTicketsApi = async (userToken) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-tickets`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
          resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

// Get Unuploaded Tickets //
export const getUnuploadedAllTicketsApi = async (userToken, sole_trader_id, ticket_id) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-unuploaded-tickets?sole_trader_id=${sole_trader_id}&ticket_id=${ticket_id}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
          resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

// Add Tickets For Contractor //
export const addTicketForContractor = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-ticket`
        try {
            let response = await axios({
                method: "POST",
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data: {
                    name: params
                }
            })
          resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

// Edit Tickets For Contractor //
export const editTicketForContractor = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/edit-ticket`
        try {
            let response = await axios({
                method: "POST",
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data: params
            })
          resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

// Delete Tickets For Contractor //
export const deleteTicketForContractor = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/delete-ticket/${params}`
        try {
            let response = await axios.delete(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            console.log(response.data)
          resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

