import axios from "axios";

export const updateContractorApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/update-profile`
        try{
            let response = await axios({
                method: "POST",
                headers: {
                  Authorization: userToken,
                  'Content-Type': 'multipart/form-data'
                },
                url: url,
                data: params
              })
            resolve(response.data.data)
        }catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

export const fatchContractorApi = async (userToken, params)=>{
    return new Promise(async (resolve, reject)=>{
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-profile`
        try{
            let response = await axios({
                method: "GET",
                headers: {
                  Authorization: userToken,
                  'Content-Type': 'multipart/form-data'
                },
                url: url
              })
            
            resolve(response.data.data)
        }catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

