import axios from "axios";

// Contractor Login //
export const logInApi = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/login`,
        data: {
          email: params.email,
          password: params.password,
        }
      })
      resolve(response?.data?.data)
    } catch (error) {
      console.log("error::", error);
      reject(error.response)
    }
  })
}
// Contractor updatePassword //
export const updateDetails = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
     
      let response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/set-details?id=${params.id}`,
        data: {
          password: params.password,
          confirm_password:params.confirm_password,
        }
      })
      resolve(response?.data?.data)
    } catch (error) {
      console.log("error::", error);
      reject(error.response.data)
    }
  })
}

// Contractor forgotPasswordApi //
export const forgotPasswordApi = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/forgot-password`,
        data: params
      })
      resolve(response?.data?.data)
    } catch (error) {
      console.log("error::", error);
      reject(error.response.data)
    }
  })
}
// Contractor forgotPasswordLinkApi //
export const forgotPasswordLinkApi = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/forgot-password-send-link`,
        data: params
      })
      resolve(response?.data?.data)
    } catch (error) {
      console.log("error::", error);
      reject(error.response.data)
    }
  })
}