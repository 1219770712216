import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";

// Style //
import "./TrainingMatrix.css";

// Images //
import { icons } from "../../../assets";

// Table //
import Table from "react-bootstrap/Table";
import TableRowLoader from "../sleketon-loader/TableRowLoader";
// Components //
import PageHeading from "../page-heading/PageHeading";
import ButtonComp from "../button/ButtonComp";
import { checkTicketStatus, dateFormateFullMonth } from "../../../constents";
import Pagination from "rc-pagination";

// Hooks //
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// API's //

import {
  getAllTicketsApi,
  getTrainingMatrixApi,
} from "../../../api/trainingMatrixApis";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";
import { getSiteTrainingApi } from "../../../api/siteProjectApi";

// Actions //

const SiteTrainingMatrix = (routes) => {
  // Hooks //
  const { userToken } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const [ticketsData, setTicketsData] = useState([]);
  const [trainingMatrixData, setTrainingMatrixData] = useState([]);
  const navigate = useNavigate();
  const pdfContentRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState("10");
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const siteId = searchParams.get("site_id");
  const location = useLocation();
  const siteInfo = location.state.siteInfo;

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return ""; // Return an empty string if the input date is undefined or null
    }

    // Parse the input date using the format "DD/MM/YYYY"
    const [day, month, year] = inputDate.split("/");
    const parsedDate = new Date(`${year}-${month}-${day}`);

    // Check if the parsedDate is a valid date
    if (isNaN(parsedDate.getTime())) {
      return ""; // Return an empty string if the parsed date is not valid
    }

    const formattedDate = parsedDate.toISOString().split("T")[0];

    return formattedDate;
  };
  const formattedStartDate = formatDate(siteInfo?.working_period_start);
  const formattedEndDate = formatDate(siteInfo?.working_period_end);

  const getAllTicketsData = async () => {
    try {
      dispatch(requestSent());
      const tickets = await getAllTicketsApi(userToken);
      const data = await getSiteTrainingApi(userToken, {
        siteId,
        formattedStartDate,
        formattedEndDate,
      });
      setTicketsData(tickets.data.rows);
      setTrainingMatrixData(data.data);
      dispatch(responseRecived());
    } catch (err) {
      dispatch(receivedError(err));
      dispatch(responseRecived());
      console.log("Error from Training matrix ", err);
    }
  };
  useEffect(() => {
    getAllTicketsData();
  }, []);
  console.log(JSON.stringify(trainingMatrixData), "aasssssss");

  // Handler //
  const updatePageHandler = (p) => {
    setSearchParams({ page: p });
    setCurrentPage(p);
    getAllTicketsData(p);
  };

  const handleExportPdfHandler = () => {
    const content = pdfContentRef.current;
    if (!content) {
      console.error("Content not found");
      return;
    }

    // myItemRef.current.style.display = 'block';
    content.childNodes[1].style.display = "none";

    const pdfOptions = {
      margin: 10,
      filename: "downloaded.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a1", orientation: "portrait" },
      exclude: [".btn_comp"],
    };

    html2pdf(content, pdfOptions);
    content.childNodes[1].style.display = "flex";
  };

  return (
    <div>
      {trainingMatrixData?.map((item, index) => {
        return (
          <div key={index}>
            <h3>{item?.Contractors[0]?.Company.name}</h3>
            <h5>{item?.TimeSheets[0]?.Site?.name}</h5>
            <h6>{item?.TimeSheets[0]?.Site?.address}</h6>
          </div>
        );
      })}
      <PageHeading title={"Site Training Matrix"} />

      <div className="d-flex gx-5justify-content-between align-items-center my-3 w-50">
        <h3 className="me-5">
          {siteInfo?.working_period_start} - {siteInfo?.working_period_end}
        </h3>
        <ButtonComp
          onClick={handleExportPdfHandler}
          title={"Export To PDF"}
          width={"49%"}
          bgColor={"white"}
          style={{
            color: "black",
            marginRight: "5px",
            border: "1px solid black",
            padding: "1rem",
          }}
        />
      </div>
      <div ref={pdfContentRef}>
        {loading ? (
          <TableRowLoader limit={8} />
        ) : (
          <div className="training_matrix_table_cont">
            <div class="fixTableHead">
              <Table className="training_martix_table" bordered>
                <thead className="fixed-header">
                  <tr>
                    <th className="bg-secondary">
                      <img
                        className="table_icon"
                        src={icons.subContractor}
                        alt=""
                      />
                    </th>
                    {ticketsData?.map((item, index) => (
                      <th key={index} className="bg-secondary text-light">
                        {item?.name}
                        {console.log(item?.name)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {trainingMatrixData?.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {item?.SoleTraderProfile ? (
                          <b>{`${item?.SoleTraderProfile?.firstname} ${item?.SoleTraderProfile?.surname}`}</b>
                        ) : (
                          <b>{item?.email}</b>
                        )}
                      </td>

                      {ticketsData?.map((elem, index) => (
                        <td key={index}>
                          {item?.SoleTraderTickets?.filter(
                            (filteredticket) =>
                              filteredticket?.Ticket?.name === elem?.name
                          )?.map((ticket, index) => {
                            return (
                              <p
                                key={index}
                                className={`tickets-date ${checkTicketStatus(
                                  ticket?.expiry_date,
                                  ticket.Ticket.createdAt
                                )}`}
                              >
                                {dateFormateFullMonth(ticket.expiry_date)}
                              </p>
                            );
                          })}
                          {item?.SoleTraderTickets.filter(
                            (filteredticket) =>
                              filteredticket?.Ticket?.name === elem?.name
                          ).length === 0 && (
                            <p className="not-provided tickets-date">
                              Not Provided
                            </p>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        <div className="pagination_cont mt-3">
          <Pagination
            pageSize={limitPerPage}
            onChange={updatePageHandler}
            current={currentPage}
            total={totalCount}
          />
        </div>

        <div className="indicators_cont">
          <div className="indicators i-1">
            <span></span>
            <p>EXPIRED</p>
          </div>
          <div className="indicators i-2">
            <span></span>
            <p>EXPIRING SOON</p>
          </div>
          <div className="indicators i-3">
            <span></span>
            <p>NOT PROVIDED</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteTrainingMatrix;
