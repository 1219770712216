import React, { useState, useEffect } from "react";

// Style //
import "../Login/LogIn.css";

// Images //
import { images } from "../../../assets/index";

// Components //
import {
  FormInput,
  ButtonComp,
  Loader,
} from "../../../common/components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Actions //
import { loginUser } from "../../../redux/actions/authAction";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsActions";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// API's //
import { forgotPasswordApi } from "../../../api/authApi";

const ForgotPassword = () => {
  const location = useLocation();

  // Hooks //
  const navigate = useNavigate();
  const [forgotePasswordData, setForgotePasswordData] = useState({
    token: "",
    password: "",
    confirm_password: "",
    type: "CONTRACTOR",
  });
  const { loading } = useSelector((state) => state.utils);
  const [error, setError] = useState("");
  const [respMessage,setRespMessage] = useState('')
  const [modalShow,setModalShow] = useState(false)

  const dispatch = useDispatch();

  // API Calls //
  const forgotPassApiCall = async () => {
    try {
      dispatch(requestSent());
      setError("");
      // Login Api Call //
      const data = await forgotPasswordApi(forgotePasswordData);
      setRespMessage(data.message)
      dispatch(responseRecived());
      setModalShow(true)
    } catch (error) {
      console.log("Login Page error::", error);
      // dispatch(receivedError(error));
      setError(error);
      setModalShow(false)
      dispatch(responseRecived());
    }
  };

  // Hendler //
  const forgotPassWordHendler = async () => {
    if (
      !forgotePasswordData.password ||
      !forgotePasswordData.confirm_password
    ) {
      alert("Please Fill all Field");
      return;
    }
    forgotPassApiCall();
  };

  const modalHendler = () => {
    dispatch(receivedError(null));
    setModalShow(false)
    navigate("/")
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      forgotPassWordHendler();
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    const token = pathname.split("/forgot-password/")[1];
    setForgotePasswordData({
      ...forgotePasswordData,
      token: token,
    });
  }, []);

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="ml"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>New Password Successfully Changed</h2>
            <p>{respMessage}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={modalHendler}>
            Log In
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CustomModal />
      <div className="login_wraper" id="forgot_pass">
        {loading ? <Loader /> : ""}
        <div className="login_form">
          <div className="header">
            <img src={images.subbyLogo} alt="" />
            <h2>Forgot Password</h2>
          </div>
          <div className="inp_fields_cont">
            <FormInput
              lable={"New Password"}
              type={"text"}
              placeholder={"Enter New Password"}
              width={"100%"}
              onchange={(value) =>
                setForgotePasswordData({
                  ...forgotePasswordData,
                  password: value,
                })
              }
              value={forgotePasswordData.password}
              onKeyPress={handleKeyPress}
              error={error?.error_key === "password" ? true : false}
            />
            <FormInput
              lable={"Confirm Password"}
              type={"text"}
              placeholder={"Enter Confirm Password"}
              width={"100%"}
              onchange={(value) =>
                setForgotePasswordData({
                  ...forgotePasswordData,
                  confirm_password: value,
                })
              }
              value={forgotePasswordData.confirm_password}
              onKeyPress={handleKeyPress}
              error={error?.error_key === "confirm_password" ? true : false}
            />
            {error ? <p className="err_message">*{error?.error_message}</p> : ""}
            <ButtonComp
              title={"Confirm New Password"}
              width={"100%"}
              onClick={forgotPassWordHendler}
            />
            <div className="remembreme_forgote_pass  justify-content-center mt-5">
              <p onClick={() => navigate("/Login")}>Cancel</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
