import React, { useEffect, useState } from "react";
import { ButtonComp, PageHeading } from "../../common/components";
import { useDispatch, useSelector } from "react-redux";
import "./TrainingMatrix.css";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";
import {
  addTicketForContractor,
  deleteTicketForContractor,
  editTicketForContractor,
  getAllTicketsApi,
} from "../../api/trainingMatrixApis";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Pagination from "rc-pagination";

function EditAddTrainingMatrix() {
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [ticketName, setTicketName] = useState("");
  const [editingTicketId, setEditingTicketId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketToDelete, setTicketToDelete] = useState(0);
  const [isEditingTicket, setisEditingTicket] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(50);

  const getAllTicketsData = async (p) => {
    try {
      dispatch(requestSent());
      const tickets = await getAllTicketsApi(userToken, { page: p });
      setTotalCount(tickets.data.count)
      setTicketsData(tickets.data.rows);
      dispatch(responseRecived());
    } catch (err) {
      dispatch(receivedError(err));
      dispatch(responseRecived());
    }
  };
  useEffect(() => {
    getAllTicketsData(currentPage);
  }, []);

  const addTicket = async () => {
    try {
      if (ticketName) {
        await addTicketForContractor(userToken, ticketName);
        setTicketName("");
        getAllTicketsData();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleEditBtnPress = (name, id) => {
    setisEditingTicket(true);
    setTicketName(name);
    setEditingTicketId(id);
  };

  const handleEditSave = async () => {
    if (ticketName) {
      await editTicketForContractor(userToken, {
        ticket_id: editingTicketId,
        name: ticketName,
      });
      setTicketName("");
      setisEditingTicket(false);
      getAllTicketsData();
    }
  };

  const handleEditCancel = () => {
    setTicketName("");
    setisEditingTicket(false);
  };

  const handleDelete = (id) => {
    setModalShow(true);
    setTicketToDelete(id);
  };

  const handleConfirmDelete = async () => {
    await deleteTicketForContractor(userToken, ticketToDelete);
    setModalShow(false);
    getAllTicketsData();
  };

  const updatePageHandler = (p) => {
    setCurrentPage(p);
    getAllTicketsData(p)
  };

  // On Page Components //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <h2>By deleting this module, it will delete all stored data, are you sure you want to continue ?</h2>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn_style"
            variant="secondary"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="orange_btn btn_style"
            onClick={handleConfirmDelete}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CustomModal />
      <PageHeading title="Training Matrix : Add/Edit/Delete Ticket" />
      <div className="right-sec_cont">
        <div className="px-5 border w-50 m-5 rounded py-3">
          <div className="d-flex justify-content-between align-items-center">
            <input
              className="add-training-input"
              type="text"
              value={ticketName}
              onChange={(e) => setTicketName(e.target.value)}
            />
            {isEditingTicket ? (
              <>
                <ButtonComp
                  onClick={handleEditSave}
                  title={"Save"}
                  width={"35%"}
                  style={{ padding: "0.8rem" }}
                />
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  style={{ padding: "0.3rem" }}
                  onClick={handleEditCancel}
                ></i>
              </>
            ) : (
              <ButtonComp
                onClick={addTicket}
                title={"Add Ticket"}
                width={"35%"}
                style={{ padding: "0.8rem" }}
              />
            )}
          </div>
        </div>

        {ticketsData?.map((item, index) => (
          <div className="edit_module_row" key={item.id}>
            <h1>{item.name}</h1>
            <button onClick={() => handleEditBtnPress(item.name, item.id)}>
              Edit Name
            </button>
            <button onClick={() => handleDelete(item.id)}>Delete Ticket</button>
          </div>
        ))}
        {/* <th key={index}>{item?.name}</th> */}
      <div className="pagination_cont justify-content-end">
        <Pagination
          pageSize={10}
          style={{ maxWidth: '100%' }}
          onChange={updatePageHandler}
          current={currentPage}
          total={totalCount}
        />
      </div>
      </div>
    </>
  );
}

export default EditAddTrainingMatrix;
