import React, { useState, useEffect } from "react";

// Components //
import {
  PageHeading,
  DropDown,
  TimeSheetAccordianTable,
} from "../../common/components/index";
import Pagination from "rc-pagination";

// API's //
import { getTimeSheetBySite } from "../../api/timeSheetApi";

// Actions //
import {
  receivedError,
  responseRecived,
  requestSent,
} from "../../redux/actions/utilsActions";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// Icons //
import {icons} from "../../assets/index"

const TimeSheetAccordian = () => {
  // Hooks //
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [siteTimeSheetData, setSiteTimeSheetData] = useState(null);
  const [showTimeSheetText, setShowTimeSheetText] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let siteId = searchParams.get("site_id");

  // API Call's //
  const getTimeSheetDataBySite = async () => {
    try {
      dispatch(requestSent());
      const data = await getTimeSheetBySite(userToken, {
        limit: limitPerPage,
        page: currentPage,
        site_id: siteId,
      });

      if (siteId) {
     
        const filteredSite = data.rows?.filter((item) => item.id == siteId);
        setSiteTimeSheetData(filteredSite);
      } else {
        setSiteTimeSheetData(data.rows);
      }
      if (data) {
        setShowTimeSheetText(true);
      }
      setTotalCount(data.count);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      console.log("TimeSheet Api Error :::", error);
      dispatch(responseRecived());
    }
  };

  useEffect(() => {
    getTimeSheetDataBySite();
  }, [limitPerPage, currentPage]);

  // Handlers //
  const handleShow = (value) => {
    setlimitPerPage(value);
    setCurrentPage(1);
  };

  const updatePageHandler = (p) => {
    setCurrentPage(p);
  };

  return (
    <div id="timesheet">
      <PageHeading title={"Your Timesheet"} />
      <div className="filter_cont justify-content-between">
        <DropDown
          label={"Select"}
          listItems={["1", "2", "8", "10", "15", "20", "25"]}
          onSelect={handleShow}
          value={limitPerPage}
        />
        <div className="tab-btn_cont">
          <button className="tab-one" onClick={() => navigate("/timesheets")}>
            Overview
          </button>
          <button className="tab-one active">by Site / Project</button>
        </div>
      </div>
    
      {siteTimeSheetData?.length > 0 ? (
        <>
          <div>
            <TimeSheetAccordianTable
              siteTimeSheetData={siteTimeSheetData}
              site_id={siteId}
            />
          </div>
          <div className="pagination_cont">
            <Pagination
              pageSize={limitPerPage}
              onChange={updatePageHandler}
              current={currentPage}
              total={totalCount}
            />
          </div>
        </>
      ) : (
        <>
          {showTimeSheetText ? (
            <div className="pending_time_sheet_empty">
              <div className="place_holder_img_cont">
                <img src={icons.timeSheet} alt="" />
              </div>
              <h2>There Are No Site timesheet</h2>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default TimeSheetAccordian;
